import { Box, Typography } from '@mui/material'
import React from 'react';
import styles from './styles.module.scss';

interface ourTeamProps {
  image: string;
  name: string;
  designation: string;
}

const OurTeam: React.FC<ourTeamProps> = ({ image, name, designation }) => {
  return (
    <Box className={styles.box}>
      <img src={image} alt="team-image" />
      <Box className={styles.typo}>
        <Typography><h1>{name}</h1></Typography>
        <Typography><h2>{designation}</h2></Typography>
      </Box>
    </Box>
  )
}

export default OurTeam;