import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RESEND_OTP } from '../../utils/constants';
import apiService from '../../service/apiService';

const ForgotPassword: React.FC = () => {
    const [formData, setFormData] = useState({
        email_address: ''
    });

    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await apiService.putApi(`${RESEND_OTP}${formData.email_address}`, {
                email: formData.email_address,
                // otp: value
            });

            if (response && response.data) {
                const user = response.data.user;
                console.log('Signup Successful:', user);
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/otp-verification' + "?email=" + formData.email_address + "&source=forgot-password");
            } else {
                setError('forgot failed. Please try again.');
            }
        } catch (error) {
            console.error('forgot up error:', error);
            setError('An error occurred during signup. Please try again.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Box className={styles.signUp}>
            <Container maxWidth='xl' className={styles.containerPadding}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6} className={styles.imageBox}>
                        <img src="/assets/images/forgot-banner.png" alt="login-banner" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem 0rem 2rem' }, display: 'grid' }}>
                        <Box className={styles.formBox} sx={{ padding: { xs: '1rem', md: '2rem', lg: '3rem' } }}>
                            <Typography variant="h1" gutterBottom className={styles.typo}>
                                <h1>Forgot Password</h1>
                            </Typography>
                            <Typography gutterBottom className={styles.typo}>
                                <p>Looks like your password went on a little adventure without you! No worries, we’ve got you covered.</p>
                            </Typography>
                            {error && <Typography color="error">{error}</Typography>}
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: '1rem' }} className={styles.formField}>
                                    <Box display={'flex'} justifyContent={'space-between'}>
                                        <InputLabel htmlFor="email_address" className={styles.label}>Email Id</InputLabel>
                                    </Box>
                                    <TextField
                                        id="email_address"
                                        name="email_address"
                                        variant="outlined"
                                        type='email'
                                        fullWidth
                                        value={formData.email_address}
                                        onChange={handleChange}
                                        required
                                        placeholder="johndoe123@mail.com"
                                        className={styles.inputField}
                                    />
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2, p: 1.5, textTransform: 'capitalize' }}
                                >
                                    Send Verification Code
                                </Button>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ForgotPassword;