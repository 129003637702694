// src/routes/public.routes.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home/home';
import About from '../pages/about/about';
import Membership from '../pages/membership/membership';
import SignUp from '../pages/signUp/signUp';
import Activists from '../pages/activists/activists';
import Entrepreneurs from '../pages/entrepreneurs/entrepreneurs';
import Volunteers from '../pages/volunteers/volunteers';
import ProjectManager from '../pages/projectManager/projectManager';
import Investors from '../pages/investors/investors';
import Contributors from '../pages/contributors/contributors';
import Events from '../pages/events/events';
import WorkingGroup from '../pages/workingGroup/workingGroup';
import Contracts from '../pages/contracts/contracts';
import ForgotPassword from '../pages/forgotPassword/forgotPassword';
import OtpVerification from '../pages/otpVerification/otpVerification';
import ResetPassword from '../pages/resetPassword/resetPassword';
import ContactUs from '../pages/contactUs/contactUs';
import Successful from '../pages/successful/successful';
import Projects from '../pages/projects/projects';
import ProjectDashboard from '../pages/projectDashboard/projectDashboard';
import TermConditions from '../pages/termsConditions/termsConditions';
import PrivacyPolicy from '../pages/privacyPolicy/privacyPolicy';

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/activists" element={<Activists />} />
      <Route path="/entrepreneurs" element={<Entrepreneurs />} />
      <Route path="/volunteers" element={<Volunteers />} />
      <Route path="/project-manager" element={<ProjectManager />} />
      <Route path="/investors" element={<Investors />} />
      <Route path="/contributors" element={<Contributors />} />
      <Route path="/events" element={<Events />} />
      <Route path="/working-group" element={<WorkingGroup />} />
      <Route path="/contracts" element={<Contracts />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/otp-verification" element={<OtpVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/successful" element={<Successful />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/project-dashboard" element={<ProjectDashboard />} />
      <Route path="/terms-conditions" element={<TermConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default PublicRoutes;