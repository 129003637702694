import React from 'react';
import styles from './styles.module.scss';
import { Box, Container, Grid, Typography } from '@mui/material';
import ExploreCard from '../../components/molecules/exploreCard/exploreCard';
import WelcomeBanner from '../../components/organisms/welcome-banner/welcome-banner';
import LocationCard from '../../components/organisms/locationCard/locationCard';
import ReferCard from '../../components/organisms/referCard/referCard';
import NavCard from '../../components/organisms/navCard/navCard';
import {
    BannerInterface,
    ButtonInterface,
    ExploreCardInterface,
    LocationInterface,
    ReferInterface
} from '../../interface/dashboard';
import dashboardData from '../../data/dashboard.json';

const headerButton: ButtonInterface[] = dashboardData.headerButton;
const exploreCard: ExploreCardInterface[] = dashboardData.explore;
const banner: BannerInterface[] = dashboardData.banner;
const location: LocationInterface[] = dashboardData.location;
const refer: ReferInterface[] = dashboardData.refer;

const Dashboard: React.FC = () => {
    return (
        <>
            <Box className={styles.banner}>
                <Container className={styles.paddingBox} sx={{ padding: { xs: '1rem', lg: '2rem 6rem 0 6rem' } }}>
                    <NavCard buttonData={headerButton} />
                    <Box>
                        {banner.map((item, index) => (
                            <WelcomeBanner key={index} name={item.name} message={item.message} image={item.image}></WelcomeBanner>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box >
                <Container className={styles.login}>
                    <Typography className={styles.typo} textAlign={'center'}><h1>Collaboration Explorer</h1></Typography>
                    <Grid container spacing={3} textAlign={'center'}>
                        {exploreCard.map((item, index) => (
                            <Grid item xs={12} sm={12} md={4} ><ExploreCard key={index} title={item.title} image={item.image} buttonTitle={item.buttonTitle} redirectLink={item.redirectLink}></ExploreCard>
                            </Grid>))}
                    </Grid>
                </Container>
            </Box>

            <Box margin={'1.25rem 0 3rem 0'}>
                <Container sx={{ padding: { xs: '1rem', lg: '0' } }}>
                    <Grid container>
                        {location.map((item, index) => (
                            <Grid item xs={12} md={4.4} lg={4.4} sx={{ margin: { xs: '1rem 0rem', md: '0 1rem', lg: '0 1.25rem 0 0' } }} className={styles.location}>
                                <LocationCard key={index} title={item.title} icon={item.icon} desc={item.desc}
                                    linkTitle={item.linkTitle} redirectLink={item.redirectLink} image={item.image} />
                            </Grid>))}
                        {refer.map((item, index) => (
                            <Grid item xs={12} md={7} lg={7.4} className={styles.location}>
                                <ReferCard key={index} title={item.title} desc={item.desc}
                                    referLink={item.referLink} image={item.image} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Dashboard;