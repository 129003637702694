import React from 'react';
import styles from './styles.module.scss'
import { Box, Grid, Typography } from '@mui/material';
import MissionCard from '../../molecules/missionCard/missionCard';

interface MissionCardProps {
  id: string;
  image: string;
  imageBg?: string;
  title: string;
  description?: string;
}

interface MissionProps {
  title: string;
  description?: string;
  missionCard: MissionCardProps[];
}

const Mission: React.FC<MissionProps> = ({ title, description, missionCard }) => {
  return (
    <Box>
      <Box>
        <Typography className={styles.mission}>
          <h1>{title}</h1>
        </Typography>
        {description && (<Typography className={styles.mission}>
          <p>
            {description}
          </p>
        </Typography>)}
      </Box>
      <Grid container spacing={2} justifyContent={'center'}>
        {missionCard.map((data) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            lg={2.4}
            key={data.id}
            sx={{ xs: { marginBottom: "2rem" }, lg: { marginBottom: "0" } }}
          >
            <MissionCard missionCardData={data} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Mission;