import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import PartnerCard from '../../molecules/partnerCard/partnerCard';

interface partnerInterface {
  image: string;
  cardTitle: string;
  cardTitle2: string;
}

interface PartnerProps {
  title: string;
  description: string;
  partnerTitle: partnerInterface[];
}

const Partner: React.FC<PartnerProps> = ({ title, description, partnerTitle }) => {
  return (
    <Box>
      <Box>
        <Typography className={styles.mission}>
          <h1>{title}</h1>
        </Typography>
        <Typography className={`${styles.mission} ${styles.typoPMargin}`}>
          <p>{description}</p>
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{ padding: { lg: '1rem 2rem' } }}
        className={styles.justifyContent}
      >
        {partnerTitle.map((partner, index) => (
          <Grid item xs={6} md={2.4} key={index}>
            <PartnerCard cardTitle={partner.cardTitle} cardTitle2={partner.cardTitle2} image={partner.image} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Partner;
