import React, { useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    TextField,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import styles from './styles.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Project } from '../../interface/projectDashboard';
import projectDashboardData from '../../data/projectDashboard.json';

const projects: Project[] = projectDashboardData.projects;

const ProjectDashboard: React.FC = () => {
    const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);

    const handleToggle = (project: Project) => {
        setSelectedProjects((data) =>
            data.includes(project) ? data.filter((p) => p !== project) : [...data, project]
        );
    };

    return (
        <Box className={styles.projectDashboard}>
            <Grid container>
                <Grid item xs={2.3} className={styles.sidebar}>
                    <Box className={styles.sidebarContentsBox}>
                        <Typography variant="h6"><h5>Your Projects</h5></Typography>
                        <Typography><p>Total Projects {projects.length}</p> </Typography>
                        <TextField
                            placeholder="Search..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ fontSize: '20px' }} />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                height: '40px',
                                '& .MuiInputBase-root': {
                                    height: '40px'
                                }
                            }}
                        />
                    </Box>
                    <List>
                        {projects.map((project) => (
                            <ListItem key={project.id}
                                sx={{
                                    margin: '1rem 0',
                                    backgroundColor: selectedProjects.includes(project) ? '#BDD2EA' : 'transparent',
                                    boxShadow: selectedProjects.includes(project) ? '0px 12px 12px 0px #5E8CBE21' : 'none'
                                }}
                            >
                                <FormControlLabel
                                    className={styles.sidebarMenu}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontFamily: 'Inter'
                                        }
                                    }}
                                    control={
                                        <Checkbox
                                            checked={selectedProjects.includes(project)}
                                            onChange={() => handleToggle(project)}
                                        />
                                    }
                                    label={project.name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={9.7} className={styles.mainContent}>
                    <Typography variant="h3"><h3>Top Priorities</h3></Typography>

                    {selectedProjects.map((project) => (
                        <Box>
                            <Box className={styles.projectHeader}>
                                <p>{project.name}</p>
                                <a href="" style={{ alignItems: 'center' }} >Details Page <LaunchOutlinedIcon sx={{ fontSize: '16px', marginLeft: '0.25rem' }} /></a>
                            </Box>

                            <Grid container sx={{ mt: 1, mr: 1 }} key={project.id}>
                                <Grid item md={6} sx={{ pr: 1 }}>
                                    <TableContainer className={styles.tableBox}>
                                        <Table aria-label="projects table">
                                            <TableHead>
                                                <TableRow className={styles.tableRowBox}>
                                                    <TableCell className={styles.tableCellBorder}><Typography className={styles.tableCellHeadfont}>Folders</Typography></TableCell>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Links</Typography></TableCell>
                                                    <TableCell className={styles.tableCellBorder}><Typography className={styles.tableCellHeadfont}></Typography></TableCell>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Frequently Use</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {project.projectData.map((projectItem, index) => (<TableBody>
                                                <TableRow
                                                    key={index}
                                                    className={styles.tableRowBox}
                                                >
                                                    <TableCell className={styles.tableCellBorder}>{projectItem.folders}</TableCell>
                                                    <TableCell className={styles.tableCellBorder}>{projectItem.slides1}<a style={{ marginLeft: 2 }} href={projectItem.slides1Link} target='_blank'>Page Link</a></TableCell>
                                                    <TableCell className={styles.tableCellBorder}>{projectItem.slides2}<a style={{ marginLeft: 2 }} href={projectItem.slides2Link} target='_blank'>Page Link</a></TableCell>
                                                    <TableCell className={styles.tableCellfont}>{projectItem.frequently_use}<a style={{ marginLeft: 2 }} href={projectItem.videoLink} target='_blank'>Video Link</a></TableCell>
                                                </TableRow>
                                            </TableBody>))}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item md={6} sx={{ pl: 1 }}>
                                    <TableContainer className={styles.tableBox}>
                                        <Table aria-label="project task table">
                                            <TableHead>
                                                <TableRow className={styles.tableRowBox}>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Sl No</Typography></TableCell>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Priority</Typography></TableCell>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Workstream</Typography></TableCell>
                                                    <TableCell><Typography className={styles.tableCellHeadfont}>Task</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {project.taskData.map((taksItem, index) => (<TableBody>
                                                <TableRow
                                                    key={index}
                                                    className={styles.tableRowBox}
                                                >
                                                    <TableCell className={styles.tableCellfont}>{index + 1}</TableCell>
                                                    <TableCell className={styles.tableCellfont}>{taksItem.priority}</TableCell>
                                                    <TableCell className={styles.tableCellfont}>{taksItem.workstream}</TableCell>
                                                    <TableCell className={styles.tableCellfont}>{taksItem.task}</TableCell>
                                                </TableRow>
                                            </TableBody>))}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProjectDashboard;