import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';

interface becomeMemberProps {
    blueTitle: string;
    title: string;
    description: string;
    boldText: string;
    image: string;
    imageOverText: string;
}

const MemberImpact: React.FC<becomeMemberProps> = ({ blueTitle, title, description, boldText, image, imageOverText }) => {
    return (
        <Box className={styles.memberImpact}>
            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <Box className={styles.imgBox} >
                        <img className={styles.img} src={image} alt="image" />
                        <Typography><h6>{imageOverText}</h6></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ paddingRight: { lg: '3rem' } }}>
                    <Typography><h1><span>{blueTitle}</span>{title}</h1></Typography>
                    <Typography><p>{description}</p></Typography>
                    <Typography><h5>{boldText}</h5></Typography>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Box className={styles.imgBox} >
                        <img className={styles.img} src={image} alt="image" />
                        <Typography><h6>{imageOverText}</h6></Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MemberImpact;