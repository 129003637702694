// import React from 'react'
// import styles from './styles.module.scss';
// import { Box, Container } from '@mui/material';
// import MembershipCard from '../../components/membershipCard/membershipCard';

// interface memberInterface {
//   id: number;
//   icon: string;
//   title: string;
//   amount: number;
//   amountTime: string;
// };

// interface memberPlanInterface {
//   id: number;
//   icon: string;
//   title: string;
//   vipIcon?: string;
//   description: string;
// }

// const memberData: memberInterface[] = [
//   {
//     id: 1,
//     icon: '/assets/images/couple.png',
//     title: 'Adults',
//     amount: 4.20,
//     amountTime: '/mo'
//   },
//   {
//     id: 2,
//     icon: '/assets/images/senior.png',
//     title: 'Senior & Students',
//     amount: 2.20,
//     amountTime: '/mo'
//   },
//   {
//     id: 2,
//     icon: '/assets/images/company.png',
//     title: 'Nonprofit Org & Business',
//     amount: 2.20,
//     amountTime: '/mo'
//   }
// ];

// const memberPlanData: memberPlanInterface[] = [
//   {
//     id: 1,
//     icon: '/assets/images/carrot.png',
//     title: 'Collaboration Explorer Access',
//     description: 'Unlimited searches and participation in events, Working groups, and public benefit contracts tailored to your membership level.'
//   },
//   {
//     id: 2,
//     icon: '/assets/images/user.png',
//     title: 'AI-Enhanced Leadership Discovery',
//     description: 'Utilize AI tools to uncover local opportunities and leaders, maximizing your impact and involvement.'
//   },
//   {
//     id: 3,
//     icon: '/assets/images/calender.png',
//     title: 'Community Engagement Tools',
//     description: 'Connect with peers through forums, discussions, and networking platforms designed to foster collaboration.'
//   },
//   {
//     id: 4,
//     icon: '/assets/images/whatsapp.png',
//     title: 'Project Management Features',
//     description: 'Propose and submit projects for consideration, driving actionable change within your community'
//   },
//   {
//     id: 5,
//     icon: '/assets/images/access-gallery.png',
//     vipIcon: '/assets/images/vip.png',
//     title: 'Custom Branding & Profile',
//     description: 'Showcase your organization or business on the platform with personalized branding and visibility within industry networks.'
//   },
//   {
//     id: 6,
//     icon: '/assets/images/free-advanced.png',
//     vipIcon: '/assets/images/vip.png',
//     title: 'Advanced Analytics Dashboard',
//     description: 'Track the impact of your projects and monitor engagement metrics to measure your contributions effectively.'
//   }
// ]

// const memberTitle = 'Become a Member and Elevate Your Impact!';
// const description = 'Through our membership, you gain exclusive access to tools, resources, and networks that empower your sustainable journey!';
// const trialButton = '14 Days Free Trail';
// const membershipPlanTitle = 'Membership plan includes';
// const memberButtonIcon = '/assets/images/vip.png';
// const memberPlanTrialButton = 'Become a Member and Elevate Your Impact!';
// const memberPlanSubmitButton = 'Continue';

// interface exclusiveInterface {
//   headerTitle: string;
//   data: {
//     image: string;
//     title: string;
//     description: string;
//   }[];
// };

// const exclusiveBannerTitle = 'Unlock Our Exclusive Special Offer:';
// const exclusiveBannerPrice = 'Just $420!';
// const exclusiveBannerImg = '/assets/images/exclusive-card-img.png'

// const exclusive: exclusiveInterface[] = [
//   {
//     headerTitle: 'Transform your commitment into impactful change with our limited-time offer!',
//     data: [
//       {
//         image: '/assets/images/exclusive-offer-1.png',
//         title: 'Empower Nonprofits:',
//         description: 'Contribute $210 directly to vital nonprofit initiatives that are reshaping communities for the better.',
//       },
//       {
//         image: '/assets/images/exclusive-offer-2.png',
//         title: 'Fuel Innovation:',
//         description: 'Invest $210 in Maayadata, our cutting-edge AI platform driving sustainable solutions and collaborative efforts.'
//       },
//       {
//         image: '/assets/images/exclusive-offer-3.png',
//         title: 'Exclusive Share Opportunity:',
//         description: 'Secure your chance to acquire 100 shares for only $1.00 each!'
//       }
//     ]
//   }
// ];

// const Membership: React.FC = () => {
//   return (
//     <>
//       <Box className={styles.membership}>
//         <Container maxWidth='xl' className={styles.containerPadding}>
//           <MembershipCard title={memberTitle}
//             description={description}
//             trialButton={trialButton}
//             member={memberData}
//             membershipPlanTitle={membershipPlanTitle}
//             memberPlan={memberPlanData}
//             memberButtonIcon={memberButtonIcon}
//             exclusive={exclusive}
//             memberPlanTrialButton={memberPlanTrialButton}
//             memberPlanSubmitButton={memberPlanSubmitButton}
//             exclusiveBannerImg={exclusiveBannerImg}
//             exclusiveBannerTitle={exclusiveBannerTitle}
//             exclusiveBannerPrice={exclusiveBannerPrice}
//           />
//         </Container>
//       </Box>
//     </>
//   )
// }

// export default Membership;

import React from 'react';
import styles from './styles.module.scss'
import { Box, Container, Grid } from '@mui/material';
import MemberImpact from '../../components/organisms/memberImpact/memberImpact';
import EngageConnectCard from '../../components/molecules/engageConnnectCard/engageConnectCard';
import {
  BecomeMemberInterface,
  ConnectGrowInterface,
  EngageInvestInterface
} from '../../interface/membership';
import membershipData from '../../data/membership.json';

const becomeMember: BecomeMemberInterface[] = membershipData.becomeMember;
const engageInvest: EngageInvestInterface[] = membershipData.engageInvest;
const connectGrow: ConnectGrowInterface[] = membershipData.connectGrow;

const Membership: React.FC = () => {
  return (
    <>
      <Box className={styles.membership}>
        <Container maxWidth='xl' sx={{ padding: { lg: '3rem 4rem' } }}>
          {becomeMember.map((item, index) => (<MemberImpact key={index}
            blueTitle={item.blueTitle}
            title={item.title}
            description={item.description}
            boldText={item.boldText}
            image={item.image}
            imageOverText={item.imageOverText}
          />))}
        </Container>
      </Box>

      <Box className={styles.membershipCard}>
        <Container maxWidth='xl' className={styles.containerPadding} sx={{ padding: { lg: '0rem 4rem 4rem 4rem' } }}>
          <Grid container sx={{ paddingBottom: { xs: '3rem', lg: '0' } }}>
            <Grid item xs={12} md={6} sx={{ paddingRight: { lg: '2rem' }, paddingBottom: '1rem', margin: { xs: '2rem 1.5rem', md: '0' } }}>
              {engageInvest.map((item, index) => (<EngageConnectCard key={index}
                title={item.title}
                icon={item.icon}
                description={item.description}
                buttonTitle={item.buttonTitle}
                buttonRedirectLink={item.buttonRedirectLink}
              />))}
            </Grid>
            <Grid item xs={12} md={6} className={styles.bgColor}>
              {connectGrow.map((item, index) => (
                <EngageConnectCard
                  key={index}
                  title={item.title}
                  learnMoreText={item.learnMoreText}
                  description={item.description}
                  buttonTitle={item.buttonTitle}
                  buttonRedirectLink={item.buttonRedirectLink}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Membership;