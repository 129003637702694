import React from 'react';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';
import CustomButton from '../../components/atoms/customButton/customButton';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from '../../interface/signIn'
import successfulData from '../../data/successful.json';

const successButtonDeskProps: ButtonProps = successfulData.success;
const successButtonMobileProps: ButtonProps = successfulData.successResp;
const successImage = successfulData.successImage;
const successTitle = successfulData.successTitle;
const successDescription = successfulData.successDescription;

const Successful: React.FC = () => {
    const navigate = useNavigate();

    const onBlueButtonClick = () => {
        navigate('/signin');
    }

    return (
        <Box className={styles.successful}>
            <img src={successImage} alt="success banner" />
            <Typography className={styles.typo}><h2>{successTitle}</h2></Typography>
            <Typography className={styles.typo}><p>{successDescription}</p></Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <CustomButton {...successButtonDeskProps} onClick={onBlueButtonClick} />
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <CustomButton {...successButtonMobileProps} onClick={onBlueButtonClick} />
            </Box>
        </Box>
    )
}

export default Successful;