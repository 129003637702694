import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import CardWithHover from '../../molecules/cardWithHover/cardWithHover';

interface bannerCard {
    image: string;
    title: string;
    redirectedLink: string
}
interface BannerProps {
    titleHeader?: string;
    title: string;
    description: string;
    description2?: string;
    cardProps: bannerCard[];
}


const Banner: React.FC<BannerProps> = ({ titleHeader, title, description, description2, cardProps }) => {

    return (
        <>
            <Box className={styles.banner}>
                <Grid container className={styles.gridView}>
                    {/* Left Section (Text Content) */}
                    <Grid
                        xs={12}
                        md={6}
                        sx={{ paddingRight: { xs: '0', md: '2rem' } }}
                    >
                        <Typography variant="h1" className={styles.typo}>
                            <h1>{title}</h1>
                        </Typography>

                        <Box className={styles.descBox}><Typography className={styles.typo}>
                            <p>{description}</p>
                        </Typography>
                            {description2 && (<Typography className={styles.typo}>
                                <p>{description2}</p>
                            </Typography>)}
                        </Box>
                    </Grid>

                    {/* Right Section (Image) */}
                    <Grid item xs={12} md={6} sx={{ paddingLeft: { xs: '0', md: '2rem' } }}>
                        {titleHeader && (<Typography className={styles.typo}><h2>{titleHeader}</h2></Typography>)}
                        <Grid container spacing={2}>
                            {cardProps.map((item, index) => (
                                <Grid item xs={6} sm={6} lg={6} key={index}>
                                    <CardWithHover title={item.title} image={item.image} redirectedLink={item.redirectedLink} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Box className={styles.descBoxResp}><Typography className={styles.typo}>
                    <p>{description}</p>
                </Typography>
                    {description2 && (<Typography className={styles.typo}>
                        <p>{description2}</p>
                    </Typography>)}
                </Box>
            </Box>
        </>
    )
}

export default Banner;