import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, InputLabel, List, TextField, Typography } from '@mui/material';
import HeaderBanner from '../../components/organisms/header-Banner/header-banner';
import apiService from '../../service/apiService';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import RoomIcon from '@mui/icons-material/Room';
import MailOutlineIcon from '@mui/icons-material/MailOutlined'
import { CONTACT_US } from '../../utils/constants';
import { AxiosError } from 'axios';
import { BannerHeader } from '../../interface/contactUs';
import contactUsData from '../../data/contactUs.json';

const headerBanner: BannerHeader[] = contactUsData.banner;
interface ErrorResponse {
    message: string;
}

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        preferences: [] as string[],
    });

    const [error, setError] = useState<string | null>(null);
    const [isFormValid, setIsFormValid] = useState(false);

    const preferenceList = [
        { label: "Volunteers", value: "Volunteers" },
        { label: "Contributors", value: "Contributors" },
        { label: "Activists & Change-Makers", value: "Activists & Change-Makers" },
        { label: "Project Managers", value: "Project Managers" },
        { label: "Entrepreneurs", value: "Entrepreneurs" },
        { label: "Investors", value: "Investors" },
        { label: "Others", value: "Others" }
    ];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await apiService.postApi(CONTACT_US, {
                name: formData.name,
                email: formData.email,
                phoneNumber: formData.phoneNumber ? parseInt(formData.phoneNumber.replace(/\D/g, ''), 10) : undefined,
                message: formData.message,
                preferences: formData.preferences
            });
            if (response && response.data) {
                const user = response.data.user;
                alert(response.data.message);
                window.location.reload()
            } else {
                alert('Sign up failed');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                const errorMessage: string = (error.response?.data as ErrorResponse)?.message || "An unexpected error occurred.";
                setError(errorMessage);
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        if (name === "preferences") {
            setFormData((prevData) => {
                const updatedPreferences = checked
                    ? [...prevData.preferences, value]
                    : prevData.preferences.filter((pref) => pref !== value);
                return { ...prevData, preferences: updatedPreferences };
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        const isValid =
            formData.name.trim() !== "" &&
            formData.email.includes("@") &&
            formData.preferences.length > 0 &&
            formData.message.trim() !== "";
        setIsFormValid(isValid);
    }, [formData]);

    return (
        <>
            <Box className={styles.contact}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    {headerBanner.map((item) => (
                        <HeaderBanner
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            infoButton={item.infoButton}
                            redirectedLink={item.redirectedLink}
                            centerData={item.centerData}
                        />
                    ))}
                </Container>
            </Box>
            <Box className={styles.contact} id='form'>
                <Container maxWidth="xl" sx={{ padding: { xs: "1rem", lg: "3rem 5rem" } }}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                            {error && <Typography color="error">{error}</Typography>}
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel className={styles.label} htmlFor="name">Full Name</InputLabel>
                                    <TextField
                                        id="name"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Please enter your full name"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="email" className={styles.label}>Email</InputLabel>
                                    <TextField
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        type='email'
                                        fullWidth
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        placeholder="Please enter your email"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="phoneNumber" className={styles.label}>Phone (Optional)</InputLabel>
                                    <TextField
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        placeholder="Please enter your phone number (optional)"
                                        className={styles.inputField}
                                    />
                                </Box>
                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="preferences" className={styles.label}>What are your preferences?</InputLabel>
                                    {preferenceList.map((preference) => (
                                        <FormControlLabel
                                            key={preference.value}
                                            className={`${styles.label} ${styles.checkBox}`}
                                            control={
                                                <Checkbox
                                                    name="preferences"
                                                    value={preference.value}
                                                    checked={formData.preferences.includes(preference.value)}
                                                    onChange={handleChange}
                                                />
                                            }
                                            label={preference.label}
                                        />
                                    ))}
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="message" className={styles.label}>Message</InputLabel>
                                    <TextField
                                        id='message'
                                        name='message'
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        rows={5}
                                        className={styles.inputField}
                                        placeholder="Please share your comments."
                                    />
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        mt: 2,
                                        p: 1.5,
                                        textTransform: "capitalize",
                                        background: isFormValid ? "#007bff" : "#A5ABBA",
                                        color: isFormValid ? "white" : "inherit",
                                    }}
                                    disabled={!isFormValid}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ marginTop: { xs: '-1rem', md: '0' } }}>
                            <Typography className={styles.typo}><p color='#1770D9'>Find Us on Google Maps</p></Typography>
                            <Box className={styles.map}>
                                <img src="/assets/images/maps.png" alt="map" />
                            </Box>
                            <List className={styles.list}>
                                <li><RoomIcon />PO Box 2531  Kingston, NY 12402</li>
                                <li><PhoneInTalkIcon /><a href="tel:+18454810550">+18454810550</a></li>
                                <li><MailOutlineIcon /><a href='mailto:pr@voicesforprogress.net'>pr@voicesforprogress.net</a></li>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default ContactUs;