import React from 'react';
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material';

interface partnerProps {
  image: string;
  cardTitle: string;
  cardTitle2: string;
}

const PartnerCard: React.FC<partnerProps> = ({ image, cardTitle, cardTitle2 }) => {
  return (
    <Box className={styles.partnerCard}>
      <img src={image} alt="partner-logo" />
      <Typography><h3>{cardTitle}</h3></Typography>
      <Typography><h3>{cardTitle2}</h3></Typography>
    </Box>
  )
}

export default PartnerCard