import { Box, Button } from '@mui/material'
import React from 'react';
import styles from './styles.module.scss';

interface buttonProps {
    buttonTitle: string;
    redirectedLink: string;
}

const RoundedButton: React.FC<buttonProps> = ({ buttonTitle, redirectedLink }) => {
    return (
        <Box className={styles.box}>
            <Button href={redirectedLink} target='_blank' className={styles.btn} variant="contained">{buttonTitle}</Button>
        </Box>
    )
}

export default RoundedButton