import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import { Box, Container, Grid, Typography } from '@mui/material';
import CommunityCard from '../../components/molecules/community-card/community-card';
import EmpowerCard from '../../components/molecules/empowerCard/empowerCard';
import OppurtunityCard from '../../components/organisms/oppurtunity-Card/oppurtunityCard';
import BenifitsCard from '../../components/organisms/benifitsCard/benifitsCard';
import CollaborationBanner from '../../components/organisms/collborationBanner/collaborationBanner';
import ProjectCard from '../../components/organisms/projectCard/projectCard';
import {
    BenifitsInterface,
    ComunityInterface,
    EmpowerInterface,
    OppurtunityCardInterface,
    ProjectsBannerInterface,
    TypeOfProjects
} from '../../interface/projects';
import projectsData from '../../data/projects.json';
import apiService from '../../service/apiService';
import { SPOTLIGHT_PROJECTS } from '../../utils/constants';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { error } from 'console';

const projectsBanner: ProjectsBannerInterface[] = projectsData.banner;
const typeProjectTitle = projectsData.typeProjectTitle;
const typeProjectDescription = projectsData.typeProjectDescription;
const oppurtunityCardData: OppurtunityCardInterface[] = projectsData.oppurtunity;
const empPowerTitle = projectsData.empPowerTitle;
const empowerCardData: EmpowerInterface[] = projectsData.empower;
const benifitsData: BenifitsInterface[] = projectsData.benifits;
const comunityCardData: ComunityInterface[] = projectsData.comunity;

const Projects: React.FC = () => {
    const [projectsData, setProjectsData] = useState<TypeOfProjects[]>([]);
    const [itemsToShow, setItemsToShow] = useState(9);

    const handleShowMore = () => {
        setItemsToShow(prev => prev + 3);
    };

    const visibleItems = projectsData.slice(0, itemsToShow);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await apiService.getApi(SPOTLIGHT_PROJECTS + "1/1000", {});
                setProjectsData(response.data.data);
            } catch (error) {
                console.error('Somethings went wrong:', error);
            }
        }
        fetchProjects();
    }, []);

    return (
        <>
            <Box className={styles.eBanner} sx={{ padding: { xs: '2rem 1.5rem', md: '3rem 4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Box>
                        {projectsBanner.map((item, index) => (
                            <div key={index}>
                                <CollaborationBanner key={index}
                                    title={item.title}
                                    colorTitle={item.colorTitle}
                                    image={item.image}
                                />
                            </div>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box sx={{ padding: { xs: '1.5rem', md: '0 1.5rem', lg: '0 8rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Box sx={{ margin: { xs: '0.5rem 0 2rem 0', sm: '3rem 0' } }}>
                        <Typography className={styles.typo}><h1>{typeProjectTitle}</h1></Typography>
                        <Typography className={styles.typo}><h6>{typeProjectDescription}</h6></Typography>
                    </Box>
                    <Box className={styles.projectsBox}>
                        <Grid container spacing={4} justifyContent={'center'}>
                            {visibleItems?.map((project) => (
                                <Grid item xs={12} sm={6} md={4} className="projects" key={project.id}>
                                    <ProjectCard
                                        id={project.id}
                                        name={project.name}
                                        people_count={project.people_count}
                                        donations={project.donations}
                                        goal_percentage={project.goal_percentage}
                                        description={project.description}
                                        project_image={project.project_image}
                                        project_links={project.project_links}
                                        goal_type={project.goal_type}
                                        startdate_home_page={project.startdate_home_page}
                                        state={project.state}
                                        city={project.city}
                                        national={project.national}
                                        nation={project.nation}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Box className={styles.showMoreData}>
                            {itemsToShow < projectsData.length && (
                                <Box className={styles.showMore} onClick={handleShowMore}>
                                    Show More <KeyboardArrowDownOutlinedIcon />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>

            <Box sx={{ padding: { xs: '1.5rem', lg: '0' }, marginTop: { xs: '0', sm: '4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Box>
                        {oppurtunityCardData.map((item, index) => (
                            <div key={index}>
                                <OppurtunityCard
                                    image={item.image}
                                    title={item.title}
                                    desc={item.desc}
                                    desc2={item.desc2}
                                />
                            </div>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box sx={{ padding: { xs: '1.5rem', md: '2rem 4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Typography className={styles.typo}><h1>{empPowerTitle}</h1></Typography>
                    <Grid container spacing={4} marginTop={'0rem'}>
                        {empowerCardData.map((item, index) => (<Grid item xs={12} lg={6}>
                            <EmpowerCard key={index} empowerCard={item}></EmpowerCard>
                        </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <Box>
                <Container maxWidth="xl" sx={{ padding: { xs: "2rem 0", lg: "1rem 4rem 3rem 4rem" } }}>
                    {benifitsData.map((item, index) => (
                        <BenifitsCard key={index}
                            title={item.title}
                            description={item.description}
                            image={item.image}
                        />
                    ))}
                </Container>
            </Box>

            <Box className={styles.events} sx={{ padding: { xs: '1.5rem', sm: '2rem' }, marginBottom: { lg: '5rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    {comunityCardData.map((item, index) => (<CommunityCard key={index} comunitydata={item}></CommunityCard>))}
                </Container>
            </Box>
        </>
    )
}

export default Projects;