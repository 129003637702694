import React from 'react';
import styles from './styles.module.scss'
import { Box, Card, Grid, Link } from '@mui/material';

interface bannerCard {
    image: string;
    title: string;
    redirectedLink: string
}

const cardWithHover: React.FC<bannerCard> = ({ image, title, redirectedLink }) => {
    return (
        <Box>
            <Link className={styles.link} href={redirectedLink}>
                <Card className={styles.cardBox}>
                    <img className={styles.cardImage} src={image} alt="banner-image" />
                    <Box className={styles.contentBox}>
                        <span>
                            {title}
                        </span>
                        <img className={styles.arrowImg} src="/assets/images/vector.svg" alt="" />
                    </Box>
                </Card>
            </Link>
        </Box>
    )
}

export default cardWithHover;