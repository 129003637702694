import React from 'react';
import styles from './styles.module.scss';
import { Box } from '@mui/material';
import PageLocationText from '../../atoms/pageLocationText/pageLocationText';
import CircularButtonGroup from '../../atoms/circularButton/circularButton';

interface CircularButton {
  id: number;
  title: string;
}

interface DashboardHeaderProps {
  selectedItem: string;
  address: string;
  icon?: string;
  onIconClick?: () => void;
  buttons: CircularButton[];
  activeBtn: number;
  onButtonClick: (id: number) => void;
}

const DashboardHeaderAction: React.FC<DashboardHeaderProps> = ({
  selectedItem,
  address,
  icon,
  onIconClick,
  buttons,
  activeBtn,
  onButtonClick,
}) => {
  return (
    <Box className={styles.box}>
      <Box>
        <PageLocationText
          selectedItem={selectedItem}
          address={address}
          icon={icon}
          onIconClick={onIconClick}
        />
      </Box>
      <Box>
        <CircularButtonGroup
          buttons={buttons}
          activeBtn={activeBtn}
          onClick={onButtonClick}
        />
      </Box>
    </Box>
  )
}

export default DashboardHeaderAction;