import React from 'react';
import styles from './styles.module.scss'
import { Box, Button, Grid, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface visionMissionProps {
  image: string;
  title: string;
  desc: string;
  buttonTitle: string;
  redirectLink: string;
}

const Vision: React.FC<visionMissionProps> = ({ image, title, desc, buttonTitle, redirectLink }) => {
  return (
    <Box>
      <Grid container>
        {/* Left  Section (Image) */}
        <Grid spacing={2} xs={12} md={5} sx={{ padding: { lg: "0" } }}>
          <Box className={styles.imageBox}>
            <img src={image} alt="vision-happy" />
          </Box>
        </Grid>

        {/* Right Section (Text Content) */}
        <Grid
          xs={12}
          md={7}
          className={styles.vision}
          sx={{ padding: { xs: "1rem", md: "4rem 0rem 5rem 5rem", position: 'relative' } }}
        >
          <Box>
            <Typography className={styles.visionTypo}>
              <h1>{title}</h1>
            </Typography>
            <Typography className={styles.visionTypo}>
              <p>{desc}</p>
            </Typography>
          </Box>
          <Box className={styles.btnBox}>
            <Box className={styles.btn}>
              <PrimaryButton buttonTitle={buttonTitle} redirectLink={redirectLink} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

export default Vision;