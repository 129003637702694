import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

interface welcomeProps {
    name:string;
    message:string;
    image:string;
}

const WelcomeBanner:React.FC<welcomeProps> =({name,message,image}) => {
  return (
    <Box>
        <Grid container className={styles.box}>
            <Grid item xs={12} md={6} lg={4.5} className={styles.contents}>
                <Typography className={styles.typo} ><h1>Welcome !</h1></Typography>
                <Typography className={styles.typo} ><h3>{name}</h3></Typography>
                <Typography className={styles.typo} ><p>{message}</p></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={7} className={styles.imgBox}>
                <img src={image} alt="welcome image" />
            </Grid>
        </Grid>
    </Box>
  )
}

export default WelcomeBanner;