// src/routes/protected.routes.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard/dashboard';
import Explore from '../pages/explore/explore';
import useAuth from '../hooks/useAuth';
import SignIn from '../pages/signIn/signIn';
import AdminDashboard from '../pages/adminDashboard/adminDashboard';

const ProtectedRoutes: React.FC = () => {
  const { isAuthenticated, userRole, signIn } = useAuth();

  return (
    <Routes>
      <Route
        path="/dashboard"
        element={isAuthenticated ? <Dashboard /> : <SignIn onSignin={signIn} />}
      />
      <Route
        path="/explore"
        element={isAuthenticated ? <Explore /> : <SignIn onSignin={signIn} />}
      />
      <Route
        path="/admin-dashboard"
        element={
          isAuthenticated && userRole === 'admin' ? (
            <AdminDashboard />
          ) : (
            <SignIn onSignin={signIn} />
          )
        }
      />
    </Routes>
  );
};

export default ProtectedRoutes;