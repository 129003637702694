import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react';
import styles from './styles.module.scss';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface oppurtunityCardProps {
  leftImage?: string;
  image?: string;
  title: string;
  desc: string;
  desc2?: string;
  darkTitle?: string;
  buttonTitle?: string;
  redirectLink?: string;
}

const OppurtunityCard: React.FC<oppurtunityCardProps> = ({ leftImage, image, title, desc, desc2, darkTitle, buttonTitle, redirectLink }) => {
  return (
    <Box>
      <Grid container>
        {/* Left  Section (Image) */}

        {image && (<Grid xs={12} lg={6} sx={{ padding: { lg: "0" }, display: { xs: 'block', lg: 'none' } }}>
          <Box className={styles.imageBox}>
            <img src={image} alt="vision-happy" />
          </Box>
        </Grid>)}

        {leftImage && (
          <Grid xs={12} lg={6} sx={{ padding: { lg: "0" } }}>
            <Box className={styles.imageBox}>
              <img src={leftImage} alt="vision-happy" />
            </Box>
          </Grid>
        )}

        <Grid
          xs={12}
          lg={6}
          className={styles.vision}
          sx={{ padding: { xs: "1.5rem", lg: "2rem 3rem 3rem 3rem" } }}
        >
          <Box
            className={styles.contentBox}
          >
            <Box>
              <Typography className={styles.visionTypo}>
                <h1>{title}</h1>
              </Typography>
              <Typography className={styles.visionTypo}>
                <p>{desc}</p>
              </Typography>
              {desc2 && (<Typography className={styles.visionTypo}>
                <p>{desc2}</p>
              </Typography>)}
              {darkTitle && (<Typography className={styles.visionTypo}>
                <h3>{darkTitle}</h3>
              </Typography>)}
            </Box>
            {buttonTitle && redirectLink && (
              <Box>
                <Box className={styles.btn}>
                  <PrimaryButton buttonTitle={buttonTitle} redirectLink={redirectLink} />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Right Section (Text Content) */}
        {image && (<Grid xs={12} lg={6} sx={{ padding: { lg: "0" }, display: { xs: 'none', lg: 'block' } }}>
          <Box className={styles.imageBox}>
            <img src={image} alt="vision-happy" />
          </Box>
        </Grid>)}
      </Grid>
    </Box>
  )
}

export default OppurtunityCard