import React from 'react';
import styles from './styles.module.scss';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Button,
    Avatar,
    AvatarGroup,
    Box,
} from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { CollaborationTableHeader, Person } from '../../../interface/adminDashboard';

interface TableRows {
    id: string;
    title?: string;
    description?: string;
    project_id?: string;
    status?: string;
    priority?: string;
    top_priority?: string;
    start_date?: string;
    end_date?: string;
    stream_id?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
    is_active?: boolean;
    is_deleted?: boolean;
    urgency?: string;
    stream?: string;
    stream_name?: string;
    task?: string;
    assigned_users?: Person[];
    reviewers?: Person[];
    reporters?: Person[];
    dueDate?: string;
    progress?: string;
    action?: string;
}

interface TableProps {
    headers: CollaborationTableHeader[];
    rows: TableRows[];
    onAddClick: () => void;
    hideEndDate?: boolean;
}

const CustomTable: React.FC<TableProps> = ({ headers, rows, onAddClick, hideEndDate }) => {
    return (
        <Box className={styles.box}>
            <TableContainer sx={{ background: '#F6FAFF', borderRadius: '0.75rem' }}>
                <Table sx={{ minWidth: 650 }} aria-label="custom table">
                    <TableHead className={styles.tableHeadRow}>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header.key}>
                                    <Typography>{header.label}</Typography>
                                </TableCell>
                            ))}
                            <TableCell>
                                <Button variant="contained" onClick={onAddClick} className={styles.btn}>
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {rows.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.priority}</TableCell>
                                <TableCell>{item.stream_name ? (item.stream_name) : (item.stream_id)}</TableCell>
                                <TableCell sx={{ color: '#003BFF' }}>{item.title}</TableCell>
                                {item.assigned_users && (<TableCell>
                                    <AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.assigned_users.map((person, idx) => (
                                            <Avatar key={idx} src={person.image} />
                                        ))}
                                    </AvatarGroup>
                                </TableCell>)}
                                {item.reviewers && (<TableCell>
                                    <AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.reviewers.map((person, idx) => (
                                            <Avatar key={idx} src={person.image} />
                                        ))}
                                    </AvatarGroup>
                                </TableCell>)}
                                {item.reporters && (<TableCell>
                                    <AvatarGroup max={3} className={styles.avtarAlign}>
                                        {item.reporters.map((person, idx) => (
                                            <Avatar key={idx} src={person.image} />
                                        ))}
                                    </AvatarGroup>
                                </TableCell>)}
                                {!hideEndDate && (
                                    <TableCell>
                                        {item.end_date
                                            ? new Date(item.end_date).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'short',
                                            })
                                            : ''}
                                    </TableCell>
                                )}
                                <TableCell>{item.status}</TableCell>
                                <TableCell>
                                    <Box className={styles.action}>
                                        <DriveFileRenameOutlineOutlinedIcon cursor="pointer" />
                                        <StarBorderOutlinedIcon cursor="pointer" />
                                        <DeleteOutlineOutlinedIcon cursor="pointer" />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box className={styles.viewAll}>View All</Box>
            </TableContainer>
        </Box>
    );
};

export default CustomTable;