import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import CustomButton from '../../components/atoms/customButton/customButton';
import { useNavigate } from 'react-router-dom';
import { SIGN_UP } from '../../utils/constants';
import apiService from '../../service/apiService';
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { AxiosError } from 'axios';
import { ButtonProps } from '../../interface/signIn'
import signUpData from '../../data/signUp.json'
interface ErrorResponse {
    message: string;
}

const facebookButtonProps: ButtonProps = signUpData.facebook;
const linkedInButtonProps: ButtonProps = signUpData.linkedIn;

const SignUp: React.FC = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        email_address: '',
        password: '',
        phone_number: ''
    });
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await apiService.postApi(SIGN_UP, {
                first_name: formData.first_name,
                email_address: formData.email_address,
                password: formData.password,
                phone_number: formData.phone_number,
                "user_role_id": "1837e2b2-76cf-4676-8b67-6cf7d1ffcc0c"
            });

            if (response && response.data) {
                const user = response.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/otp-verification' + "?email=" + formData.email_address + "&source=signup");
                alert(response.data.message);
            } else {
                alert('Sign up failed');
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                const errorMessage: string = (error.response?.data as ErrorResponse)?.message || "An unexpected error occurred.";
                setError(errorMessage);
            } else {
                setError("An unexpected error occurred.");
            }
        }
    };

    const facebookButtonClick = () => { }
    const linkedInButtonClick = () => { }

    const handleGoogleSubmit = async (name: any, email: any) => {

        try {
            const response = await apiService.postApi(SIGN_UP, {
                first_name: name,
                email_address: email,
                password: "Pass@1234",
                "user_role_id": "1837e2b2-76cf-4676-8b67-6cf7d1ffcc0c"
            });
            if (response && response.data) {
                alert(response.data.message);
                navigate('/otp-verification' + "?email=" + email + "&source=signup");
            } else {
                alert('Sign up failed');
            }
        } catch (error) {
            alert('Sign up failed');
        }
    };

    const handleGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
        try {
            const decodedData: any = credentialResponse?.credential
                ? jwtDecode(credentialResponse?.credential)
                : null;
            await handleGoogleSubmit(decodedData.name, decodedData.email)
        }
        catch (error) {
            alert('Sign up failed');
        }
    };

    return (
        <Box className={styles.signUp}>
            <Container maxWidth='xl' className={styles.containerPadding}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6} className={styles.imageBox}>
                        <img src="/assets/images/login-banner.png" alt="login-banner" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem 0rem 2rem' }, display: 'grid' }}>
                        <Box className={styles.formBox} sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem' } }}>
                            <Typography variant="h1" gutterBottom className={styles.typo}>
                                <h1>Sign Up</h1>
                            </Typography>
                            {error && <Typography color="error">{error}</Typography>}
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel className={styles.label} htmlFor="first_name">First Name</InputLabel>
                                    <TextField
                                        id="first_name"
                                        name="first_name"
                                        variant="outlined"
                                        fullWidth
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your first name"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="email_address" className={styles.label}>Email Id</InputLabel>
                                    <TextField
                                        id="email_address"
                                        name="email_address"
                                        variant="outlined"
                                        type='email'
                                        fullWidth
                                        value={formData.email_address}
                                        onChange={handleChange}
                                        required
                                        placeholder="johndoe123@mail.com"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="password" className={styles.label}>Password</InputLabel>
                                    <TextField
                                        id="password"
                                        name="password"
                                        variant="outlined"
                                        type='password'
                                        fullWidth
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your password"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="phone_number" className={styles.label}>Phone No</InputLabel>
                                    <TextField
                                        id="phone_number"
                                        name="phone_number"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                        value={formData.phone_number}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your phone number"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2, p: 1.5, textTransform: 'capitalize' }}
                                >
                                    Continue
                                </Button>

                                <Box className={styles.signupOptions}>
                                    <Typography><p>or</p></Typography>
                                    <Typography><h4>Sign Up with</h4></Typography>
                                </Box>

                            </form>
                            <Box className={styles.signinwith}>

                                <GoogleOAuthProvider
                                    clientId="655922202538-rjr5b0psj3163n7i9aehjbgj9qcaor6h.apps.googleusercontent.com">
                                    <GoogleLogin onSuccess={handleGoogleLoginSuccess} />
                                </GoogleOAuthProvider>
                            </Box>
                            <Box className={styles.signinwith}>
                                <CustomButton {...facebookButtonProps} onClick={facebookButtonClick} />
                            </Box>
                            <Box className={styles.signinwith}>
                                <CustomButton {...linkedInButtonProps} onClick={linkedInButtonClick} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
};

export default SignUp;