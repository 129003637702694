import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface exploreCardProps {
    title: string;
    image: string;
    buttonTitle: string;
    redirectLink: string;
}

const ExploreCard: React.FC<exploreCardProps> = ({ title, image, buttonTitle, redirectLink }) => {
    return (
        <Box className={styles.explore}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={styles.typo}><h1>{title}</h1></Typography>
                    <Box className={styles.imgBox}>
                        <img src={image} alt="explore-image" />
                    </Box>
                    <Box>
                        <PrimaryButton buttonTitle={buttonTitle} redirectLink={redirectLink} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ExploreCard;