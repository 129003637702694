import React from 'react';
import styles from './styles.module.scss'
import { Box, Container, List, Grid, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import RoomIcon from '@mui/icons-material/Room';
import MailOutlineIcon from '@mui/icons-material/MailOutlined'
const Footer: React.FC = () => {

  const date = new Date();

  const helpfulLink = [
    { text: 'Events', href: '/events' },
    { text: 'Membership', href: '/membership' },
    { text: 'About Us', href: '/about-us' }
  ];

  const otherLink = [
    { text: 'Contact Us', href: '/contact-us' }
  ];

  const subFooterLink = [
    { text: 'Terms & Conditions', href: '/terms-conditions' },
    { text: 'Privacy Policy', href: '/privacy-policy' }
  ];
  const socialLink = [
    { imageLink: '/assets/images/facebook.png', href: 'https://www.facebook.com/profile.php?id=61568113628127' },
    { imageLink: '/assets/images/x.png', href: 'https://x.com/VoicesForProg' },
    { imageLink: '/assets/images/linkedin.png', href: 'https://www.linkedin.com/company/voices-for-progress-bgn/about/?viewAsMember=true' }
  ];

  return (
    <>
      <Box className={styles.footer} sx={{ padding: { xs: '60px 0', lg: '50px 70px' } }}>
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between">
            <Grid lg={3} className={styles.box} spacing={3}>
              <a href="/" style={{ padding: '0' }}><img src="/assets/images/white-logo.png" alt="logo" /></a>
              <List className={styles.list}>
                <li><RoomIcon />PO Box 2531 <br /> Kingston, NY 12402</li>
                <li><PhoneInTalkIcon /><a href="tel:+18454810550">+18454810550</a></li>
                <li><MailOutlineIcon /><a href='mailto:pr@voicesforprogress.net'>pr@voicesforprogress.net</a></li>
              </List>
            </Grid>
            <Grid spacing={3} className={styles.box}>
              <h1>Helpful Links</h1>
              <List className={styles.list}>
                {helpfulLink.map((item) => (<li><a href={item.href}>{item.text}</a></li>))}
              </List>
            </Grid>
            <Grid spacing={3} className={styles.box}>
              <Typography variant='h1'>Other Links</Typography>
              <List className={styles.list}>
                {otherLink.map((item) => (<li><a href={item.href}>{item.text}</a></li>))}
              </List>
            </Grid>
            <Grid spacing={4} className={styles.box}>
              <Typography variant='h1'>Social Links</Typography>
              <List className={styles.social}>
                {socialLink.map((item) => (<a href={item.href}><span><img src={item.imageLink} alt="social-icon" /></span></a>))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box >


      <Box className={styles.subFooter} sx={{ padding: { lg: '0px 70px' } }}>
        <Container maxWidth='xl' className={styles.subFooter}>
          <Typography className={styles.copyRight} ><h6>Voices for progress Marketplace {date.getFullYear()} Copyright </h6></Typography>
          <List className={styles.subList}>
            {subFooterLink.map((item) => (<li><a href={item.href}>{item.text}</a></li>))}
          </List>
        </Container>
      </Box>
    </>
  );
};

export default Footer;