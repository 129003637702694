import React from 'react';
import styles from './styles.module.scss'
import { Box, Button, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface BannerProps {
    image: string;
    title: string;
    description: string;
    infoButton: string;
    redirectedLink: string;
    centerData: boolean;
}

const HeaderBanner: React.FC<BannerProps> = ({ image, title, description, infoButton, redirectedLink, centerData }) => {
    return (
        <Box className={styles.banner}>
            <Box position={'relative'}>
                <img src={image} alt="banner" />
                <Typography className={styles.respTypo} sx={{ display: { xs: 'block', md: 'none' } }}><h1>{title}</h1></Typography>
            </Box>
            {centerData === false && (<Box className={styles.contents}>
                <Typography className={styles.typo} sx={{ display: { xs: 'none', md: 'block' } }}><h1>{title}</h1></Typography>
                <Typography className={styles.typo}><p>{description}</p></Typography>
                <Box className={styles.btn}>
                    <PrimaryButton buttonTitle={infoButton} redirectLink={redirectedLink} />
                </Box>
            </Box>)}
            {centerData === true && (<Box className={styles.centerButtom}>
                <Typography className={styles.typo} sx={{ display: { xs: 'none', md: 'block' } }}><h1>{title}</h1></Typography>
                <Typography className={styles.typo}><p>{description}</p></Typography>
                <Box className={styles.btn}>
                    <PrimaryButton buttonTitle={infoButton} redirectLink={redirectedLink} />
                </Box>
            </Box>)}
        </Box>
    )
}

export default HeaderBanner;