import React from 'react';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';

interface missionCardInterface {
    id: string;
    image: string;
    imageBg?: string;
    title?: string;
    description?: string;
}

const MissionCard: React.FC<{ missionCardData: missionCardInterface }> = ({ missionCardData }) => {
    return (
        <>
            <Box sx={{ margin: { xs: '0 0 2rem 0', lg: '0' }, padding: { sm: '0 1rem', md: '0' } }}>
                <Box className={styles.imageBox} sx={{ background: missionCardData.imageBg }}><img src={missionCardData.image} alt="mission-icon" /></Box>
                {missionCardData.title && (<Typography className={styles.typo}><h3>{missionCardData.title}</h3></Typography>)}
                {missionCardData.description && (<Typography className={styles.typo}><p>{missionCardData.description}</p></Typography>)}
            </Box>
        </>
    )
}

export default MissionCard