import React from 'react';
import styles from './styles.module.scss';
import { Box, Slider, Typography } from '@mui/material';
import RoundedButton from '../../atoms/roundedButton/roundedButton';

interface projectProps {
    id: string;
    name: string;
    people_count?: number;
    donations: number,
    goal_percentage: number,
    description?: string;
    project_image: string;
    project_links: string;
    goal_type: string;
    startdate_home_page?: string;
    nation?: string;
    region?: string;
    state: string;
    city: string;
    county?: string;
    project_goal?: number;
    national: boolean;
    start_date?: string;
    borderColor?:string;
}

const ProjectCard: React.FC<projectProps> = ({
    id,
    name,
    people_count,
    donations,
    goal_percentage,
    description,
    project_image,
    project_links,
    goal_type,
    startdate_home_page,
    nation,
    region,
    state,
    city,
    county,
    project_goal,
    national,
    start_date,
    borderColor

}) => {

    return (
        <Box className={styles.project} key={id} border={borderColor}>
            <Box className={styles.header}>
                <Box>
                    <Typography><p>{name}</p></Typography>
                    <Box className={styles.location}>
                        <img src="/assets/images/blue-map.png" alt="icon" />
                        {nation !== "" || national === true ? (
                            <em>National</em>
                        ) : (
                            <em>{`${city}, ${state}`}</em>
                        )}
                    </Box>
                </Box>
                <img className={styles.logo} src={project_image} alt="project icon" />
            </Box>
            <Typography mb={1}>
                {(!startdate_home_page || startdate_home_page === "Awaiting Funding") && (!start_date || start_date === "") ? (
                    <span>Start Date: Awaiting Funding</span>
                ) : (
                    <span>
                        Start Date:{" "}
                        {new Date(startdate_home_page || start_date || "").toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                        })}
                    </span>
                )}
            </Typography>

            <Box className={styles.btnBox}>
                <Box className={styles.donation}>
                    <p>{goal_type} : ${donations}</p>
                    <Slider
                        disabled
                        defaultValue={goal_percentage}
                        sx={{
                            padding: '0',
                            margin: '0',
                            '& .MuiSlider-rail': {
                                backgroundColor: '#D9D9D9'
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: '#5E8CBE'
                            },
                            '& .MuiSlider-thumb': {
                                display: 'none'
                            }
                        }}
                    />
                </Box>
                <Box>
                    <RoundedButton buttonTitle="Learn More" redirectedLink={project_links} />
                </Box>
            </Box>
        </Box>
    )
}

export default ProjectCard;