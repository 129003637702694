import React from 'react';
import styles from './styles.module.scss';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import OppurtunityCard from '../../components/organisms/oppurtunity-Card/oppurtunityCard';
import Legacy from '../../components/organisms/legacy/legacy';
import Mission from '../../components/organisms/mission/mission';
import Partner from '../../components/organisms/partner/partner';
import CollaborationBanner from '../../components/organisms/collborationBanner/collaborationBanner';
import BenifitsCard from '../../components/organisms/benifitsCard/benifitsCard';
import OurTeam from '../../components/organisms/ourTeam/ourTeam';
import CustomButton from '../../components/atoms/customButton/customButton';
import { useNavigate } from 'react-router-dom';
import {
  OverviewInterface,
  MissionInterface,
  MissionCardInterface,
  LegacyInterface,
  PartnerInterface,
  ButtonProps,
  OurTeamInterface,
  JoinTeamInterface
} from '../../interface/about';
import aboutData from '../../data/about.json';

const overviewCardData: OverviewInterface[] = aboutData.overviewCardData;
const mission: MissionInterface[] = aboutData.mission;
const coreTitle = aboutData.coreTitle;
const coreData: MissionCardInterface[] = aboutData.coreData;
const legacy: LegacyInterface[] = aboutData.legacy;
const partnerTitle = aboutData.partnerTitle;
const partnerDesc = aboutData.partnerDesc;
const ourPartner: PartnerInterface[] = aboutData.ourPartner
const blueButton: ButtonProps[] = aboutData.blueButton;
const ourTeam: OurTeamInterface[] = aboutData.ourTeam;
const joinTeamData: JoinTeamInterface[] = aboutData.joinTeam;

const About: React.FC = () => {
  const navigate = useNavigate();

  const onBlueButtonClick = () => {
    navigate('/contact-us#form');
  };

  return (
    <>
      <Box className={styles.banner}>
        <Container maxWidth="xl" className={styles.containerPadding} sx={{ position: 'relative' }}>
          <Grid container className={styles.contentsBoxTop} sx={{ padding: { xs: '1.5rem', md: '3rem 5rem 0 4rem' }, }}>
            <Grid item xs={12} lg={5} sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Typography className={styles.typo}><h2>Driving Climatic Action Through Community Empowerment</h2></Typography>
            </Grid>
          </Grid>
          <img src="assets/images/about-banner.png" alt="banner-image" />
          <Grid container className={styles.contentsBox} sx={{ padding: { xs: '1.5rem', md: '3rem 5rem 0 4rem' }, display: { xs: 'none', lg: 'block' } }}>
            <Grid item xs={12} lg={6}>
              <Typography className={styles.typo}><h2>Driving Climatic Action Through Community Empowerment</h2></Typography>
            </Grid>
          </Grid>
          <Typography className={styles.bannerText}><span>Uniting voices for a greener tomorrow with hemp solutions</span></Typography>
          <Box textAlign={'center'}>
            {blueButton.map((ButtonProps, index) => <CustomButton {...ButtonProps} key={index} onClick={onBlueButtonClick} />)}
          </Box>
        </Container>
      </Box>

      <Box sx={{ padding: { xs: '1.5rem', md: '3rem 0 0 0' }, marginTop: '2rem' }}>
        <Container maxWidth="xl" className={styles.containerPadding}>
          <Box>
            {overviewCardData.map((item, index) => (
              <div key={index}>
                <OppurtunityCard
                  image={item.image}
                  title={item.title}
                  desc={item.desc}
                  darkTitle={item.darkTitle}
                  buttonTitle={item.buttonTitle}
                  redirectLink={item.redirectLink}
                />
              </div>
            ))}
          </Box>
        </Container>
      </Box>

      <Box className={styles.visionEmpower}>
        <Container maxWidth='xl' sx={{ padding: { xs: '0rem 1.5rem', md: '0rem 1rem', lg: '3rem 5rem' }, marginTop: '2rem' }}>
          <Grid container display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={12} md={6} lg={5}>
              <Typography className={styles.typo}><h1><span>vision:</span>Empowering Communities for a Sustainable Future</h1></Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} margin={'auto 0'}>
              <Typography className={styles.typo}><p>We envision a world where financial sustainability is at the forefront of environmental initiatives. Empowered communities and stakeholders will work hand-in-hand to shape policies that are equitable and foster prosperity and sustainability for all. Our goal is to create a collaborative environment where everyone can thrive while contributing to a healthier planet.</p></Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={styles.aboutTeam}>
        <img src="/assets/images/about-team-work.png" alt="banner" />
      </Box>

      <Box className={styles.eBanner} sx={{ padding: { xs: '1.5rem', md: '3rem 0rem 3rem 4rem' } }}>
        <Container maxWidth="xl" className={styles.containerPadding}>
          <Box>
            {mission.map((item, index) => (
              <div key={index}>
                <CollaborationBanner key={index} rightTitle={item.rightTitle} colorTitle={item.colorTitle} description={item.description} image={item.image} />
              </div>
            ))}
          </Box>
        </Container>
      </Box>

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "0rem 5.5rem 4rem 5.5rem", textAlign: 'center' } }}
        >
          <Mission title={coreTitle} missionCard={coreData} />
        </Container>
      </Box>

      <Box className={styles.legacy}>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem" } }}
        >
          <Legacy title={legacy[0].title} projects={legacy[0].projects} />
        </Container>
      </Box>

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem 0 5.5rem", textAlign: 'center' } }}
        >
          <Partner title={partnerTitle} description={partnerDesc} partnerTitle={ourPartner} />
        </Container>
      </Box>

      <Box>
        <Container maxWidth="xl" sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem 5rem 5.5rem" } }}>
          <Typography className={styles.typo}><h1>Our Team</h1></Typography>
          <Divider className={styles.dividerBg}></Divider>
          <Grid container spacing={4} justifyContent={'center'} className={styles.teamBox}>
            {ourTeam.map((item, index) => (<Grid item xs={12} sm={6} md={4} lg={4} sx={{ marginBottom: { xs: '2rem' } }}>
              <OurTeam key={index} image={item.image} name={item.name} designation={item.designation} />
            </Grid>))}
          </Grid>
        </Container>
      </Box>

      <Box>
        <Container maxWidth="xl" sx={{ padding: { xs: "1.5rem 0", lg: "1rem 5rem 3rem 4rem" } }}>
          {joinTeamData.map((item, index) => (
            <BenifitsCard key={index} title={item.title} description={item.description} noteTitle={item.noteTitle} buttonTitle={item.buttonTitle} redirectLink={item.redirectLink} image={item.image} />
          ))}
        </Container>
      </Box>
    </>
  );
}

export default About;