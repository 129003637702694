import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Box, Container, Grid } from '@mui/material';
import NavCard from '../../components/organisms/navCard/navCard';
import { AppBar, Tab, Tabs, useMediaQuery } from '@mui/material';
import CollaborationBanner from '../../components/organisms/collborationBanner/collaborationBanner';
import EmpowerCard from '../../components/molecules/empowerCard/empowerCard';
import {
    ButtonInterface,
    ContractsBannerInterface,
    ContractsExploreInterface
} from '../../interface/explore';
import exploreData from '../../data/explore.json';

const headerButton: ButtonInterface[] = exploreData.headerButton;
const contractsBanner: ContractsBannerInterface[] = exploreData.contractsBanner;
const workingBanner: ContractsBannerInterface[] = exploreData.workingBanner;
const contractsExplore: ContractsExploreInterface[] = exploreData.contractsExplore;
const workingExplore: ContractsExploreInterface[] = exploreData.contractsExplore;

const TabsData = {
    tabs: [
        {
            label: "Events"
        },
        {
            label: "Contracts"
        },
        {
            label: "Working Groups"
        }
    ]
};

const Explore: React.FC = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const isSmallScreen = useMediaQuery('(max-width:680px)');

    const handleChange = (event: any, newValue: any) => {
        setSelectedTabIndex(newValue);
    };
    return (
        <>
            <Box className={styles.explore}>
                <Container sx={{ padding: { xs: '1rem', lg: '2rem 6rem 2rem 6rem' } }}>
                    <NavCard buttonData={headerButton} />
                </Container>
            </Box>

            <Box className={styles.explore}>
                <Container maxWidth='xl' sx={{ padding: { xs: '1rem', lg: '1rem 5rem' } }}>
                    <AppBar position="static" sx={{ boxShadow: 'none', background: 'transparent' }}>
                        <Tabs value={selectedTabIndex} onChange={handleChange} orientation={isSmallScreen ? 'vertical' : 'horizontal'} sx={{
                            '& .MuiTabs-indicator': {
                                height: '0.25rem',
                                borderRadius: '0.5rem',
                            },
                        }}>
                            {TabsData.tabs.map((tab, index) => (
                                <Tab key={index} label={tab.label} sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '2rem',
                                    color: '#1770D9',
                                    textTransform: 'capitalize',
                                    '&.Mui-selected': {
                                        color: '#1770D9 !important',
                                        fontWeight: 600,
                                    },
                                    '&.MuiTab-root': {
                                        color: '#A5ABBA',
                                        fontWeight: 500,
                                    },
                                }} />
                            ))}
                        </Tabs>
                    </AppBar>

                    {selectedTabIndex === 0 && (
                        <Box className={styles.tabDivider}>
                        </Box>
                    )}

                    {selectedTabIndex === 1 && (
                        <Box>
                            <Box className={styles.tabDivider}>
                                {contractsBanner.map((item, index) => (
                                    <div key={index}>
                                        <CollaborationBanner
                                            title={item.title}
                                            colorTitle={item.colorTitle}
                                            description={item.description}
                                            buttonTitle={item.buttonTitle}
                                            redirectedLink={item.redirectedLink}
                                            image={item.image}
                                        />
                                    </div>
                                ))}
                            </Box>

                            <Box sx={{ marginBottom: '3rem' }}>
                                <Grid container spacing={4}>
                                    {contractsExplore.map((item, index) => (
                                        <Grid item xs={12} lg={6} key={index}>
                                            <EmpowerCard empowerCard={item} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    )}

                    {selectedTabIndex === 2 && (
                        <Box>
                            <Box className={styles.tabDivider}>
                                {workingBanner.map((item, index) => (
                                    <div key={index}>
                                        <CollaborationBanner
                                            title={item.title}
                                            colorTitle={item.colorTitle}
                                            description={item.description}
                                            buttonTitle={item.buttonTitle}
                                            redirectedLink={item.redirectedLink}
                                            image={item.image}
                                        />
                                    </div>
                                ))}
                            </Box>

                            <Box sx={{ marginBottom: '3rem' }}>
                                <Grid container spacing={4}>
                                    {workingExplore.map((item, index) => (
                                        <Grid item xs={12} lg={6} key={index}>
                                            <EmpowerCard empowerCard={item} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </Container>
            </Box>
        </>
    )
}

export default Explore;