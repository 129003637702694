import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Typography } from '@mui/material';

interface buttonProps {
    id: number;
    label: string;
}

const NavCard: React.FC<{ buttonData: buttonProps[] }> = ({ buttonData }) => {
    const [activeButton, setActiveButton] = useState<number | null>(1);

    const handleButtonClick = (buttonId: number) => {
        setActiveButton(activeButton === buttonId ? null : buttonId);
    };

    return (
        <Box className={styles.headerMenu} display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={styles.typo}>
                <h4>Home</h4>
            </Typography>
            <Box display="flex" alignItems="center">
                {buttonData.map((item) => (
                    <Button
                        key={item.id}
                        variant="contained"
                        onClick={() => handleButtonClick(item.id)}
                        className={styles.btn}
                        sx={{
                            borderRadius: '50%',
                            bgcolor: activeButton === item.id ? '#FFFFFF' : '#E2E2E2',
                            color: activeButton === item.id ? '#1770D9' : '#353535',
                            border: activeButton === item.id ? '2px solid #1770D9' : '2px solid #FFFFFF',
                            marginRight: '8px',
                            '&:hover': {
                                bgcolor: activeButton === item.id ? '' : '#D1D1D1',
                            }
                        }}
                    >
                        {item.label}
                    </Button>
                ))}
            </Box>
        </Box>
    );
}

export default NavCard;
