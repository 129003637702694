import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';

import styles from './styles.module.scss';
import { SidebarItem } from '../../../pages/adminDashboard/adminDashboard';

interface SidebarMenuProps {
    selectedItem: SidebarItem;
    sidebarIcons: Record<SidebarItem, React.ReactNode>;
    onSidebarClick: (item: SidebarItem) => void;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ selectedItem, sidebarIcons, onSidebarClick }) => {
    return (
        <Box className={styles.sidebar}>
            <img src="/assets/images/black-logo.png" alt="logo" />
            <List sx={{ width: '100%' }}>
                {(Object.keys(sidebarIcons) as SidebarItem[]).map((text) => (
                    <ListItem key={text} sx={{ px: 0 }}>
                        <ListItemButton
                            onClick={() => onSidebarClick(text)}
                            sx={{
                                backgroundColor: selectedItem === text ? '#1770D9' : 'inherit',
                                color: selectedItem === text ? '#FFFFFF' : '#4A4A4A',
                                padding: '1rem',
                                '&:hover': {
                                    backgroundColor: selectedItem === text ? '#1770D9' : 'rgba(255, 255, 255, 0.2)',
                                },
                            }}
                        >
                            <ListItemIcon sx={{ color: selectedItem === text ? '#FFFFFF' : '#4A4A4A', minWidth: '30px' }}>
                                {sidebarIcons[text]}
                            </ListItemIcon>
                            <Typography className={styles.listItem}>{text}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default SidebarMenu;
