import { Box, Container, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss'
import { Circle } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Data, DataWithTitle, Security, Table } from '../../interface/termsConditions';
import termsConditionData from '../../data/termsConditions.json';

const TermConditions: React.FC = () => {

    const tableOfContents: Table[] = termsConditionData.table;
    const definitions: DataWithTitle[] = termsConditionData.definitions;
    const acceptance: Data[] = termsConditionData.acceptance;
    const userAcount: Data[] = termsConditionData.userAcount;
    const accountSecurity: Security[] = termsConditionData.accountSecurity;
    const acountRecovery: Data[] = termsConditionData.acountRecovery;
    const accountTermination: Data[] = termsConditionData.accountTermination;
    const dataCollection: Data[] = termsConditionData.dataCollection;
    const gdprCompliance: Data[] = termsConditionData.gdprCompliance;
    const ccpaCompliance: Data[] = termsConditionData.ccpaCompliance;
    const dataRetention: Data[] = termsConditionData.dataRetention;
    const cookiesAndTracking: Data[] = termsConditionData.cookiesAndTracking;
    const technicalRequirements: Data[] = termsConditionData.technicalRequirements;
    const acceptableUse: Data[] = termsConditionData.acceptableUse;
    const donations: Data[] = termsConditionData.donations;
    const recurringPayments: Data[] = termsConditionData.recurringPayments;
    const ownership: Data[] = termsConditionData.ownership;
    const licenseTerms: Data[] = termsConditionData.licenseTerms;
    const contentStandards: Data[] = termsConditionData.contentStandards;
    const monitoringAndRemoval: Data[] = termsConditionData.monitoringAndRemoval;
    const securityMeasures: Data[] = termsConditionData.securityMeasures;
    const breachNotification: Data[] = termsConditionData.breachNotification;
    const availability: Data[] = termsConditionData.availability;
    const support: Data[] = termsConditionData.support;
    const liabilityLimitations: Data[] = termsConditionData.liabilityLimitations;
    const disputeResolution: Data[] = termsConditionData.disputeResolution;
    const organizationInformation: Data[] = termsConditionData.organizationInformation;
    const financialReporting: Data[] = termsConditionData.financialReporting;

    return (
        <>
            <Container maxWidth="xl" className={styles.terms}>
                <Box my={3} sx={{ padding: { xs: '1.5rem', lg: '0.5rem 6rem' } }}>
                    <Typography variant="h3" textAlign={'center'} className={styles.box}>
                        <h3>Terms and Conditions</h3>
                    </Typography>
                    <Typography variant="h5" textAlign={'center'} my={4} sx={{ textDecoration: 'underline' }}>
                        <h5>VOICES FOR PROGRESS Terms and Conditions Last updated on November 15, 2024</h5>
                    </Typography>
                    <Box>
                        <Typography variant="h5">
                            <h5>Table of Contents</h5>
                        </Typography>
                        <List>
                            {tableOfContents.map((section, index) => (
                                <ListItem key={index}>
                                    <Typography sx={{ display: 'flex' }}><span style={{ marginRight: '0.25rem' }}>{index + 1}.</span><p>{section.title}</p></Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>1. Introduction</h5>
                        </Typography>
                        <Typography sx={{ display: 'flex' }}><p>Welcome to Voices for Progress ("we," "us," "our," or "the Company"). By accessing or using our services at<strong ><a href="/">voicesforprogress.azurewebsites.net</a> or <a href="/" style={{ marginRight: '-0.5rem' }}>voicesforprogress.net</a></strong>, or when offered by Voices for Progress as an equivalent service from a different domain, you agree to these Terms and Conditions ("Terms"). Please read them carefully.</p></Typography>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>2. Definitions</h5>
                        </Typography>
                        <Box>
                            {definitions.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p><strong>{item.title}</strong>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>3. Acceptance of Terms</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>3.1. By using our site, you confirm that you:</h6>
                        </Typography>
                        <Box>
                            {acceptance.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                        <Typography variant="h6" gutterBottom>
                            <h6>3.2. If you disagree with any part of these Terms, please refrain from using our services.</h6>
                        </Typography>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>4. User Accounts</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>4.1. Account Creation</h6>
                        </Typography>
                        <Box>
                            {userAcount.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>4.2. Account Security</h5>
                        </Typography>
                        <Box>
                            {accountSecurity.map((item, index) => (
                                <Box>
                                    <Typography key={index} display={'flex'} margin={'0.5rem 0'} alignItems="center">
                                        <Circle className={styles.circleBox} />
                                        <h6>{item.title}</h6>
                                    </Typography>
                                    <Box display={'block'} flexDirection="column" marginLeft={3}>
                                        {item.data.map((data, dataIndex) => (
                                            <Box key={dataIndex} display="flex" alignItems="center">
                                                <CircleOutlinedIcon className={styles.circleBox} />
                                                <Typography>{data.password}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>4.3. Account Recovery</h5>
                        </Typography>
                        <Box>
                            {acountRecovery.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>4.4. Account Termination</h5>
                        </Typography>
                        <Box>
                            {accountTermination.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>5. Privacy and Data Protection</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>5.1. Data Collection</h6>
                        </Typography>
                        <Typography gutterBottom>
                            <p>We collect:</p>
                        </Typography>
                        <Box>
                            {dataCollection.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>5.2. GDPR Compliance (European Users)</h5>
                        </Typography>
                        <Typography gutterBottom>
                            <p>Rights include:</p>
                        </Typography>
                        <Box>
                            {gdprCompliance.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>5.3. CCPA Compliance (California Residents)</h5>
                        </Typography>
                        <Typography gutterBottom>
                            <p>Rights include:</p>
                        </Typography>
                        <Box>
                            {ccpaCompliance.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>5.4. Data Retention</h5>
                        </Typography>
                        <Box>
                            {dataRetention.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>5.5. Cookies and Tracking</h5>
                        </Typography>
                        <Box>
                            {cookiesAndTracking.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>6. Service Usage and Limitations</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>6.1. Technical Requirements</h6>
                        </Typography>
                        <Box>
                            {technicalRequirements.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>6.2. Acceptable Use</h5>
                        </Typography>
                        <Typography gutterBottom>
                            <p>Users must not:</p>
                        </Typography>
                        <Box>
                            {acceptableUse.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>7. Financial Terms</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>7.1. Donations</h6>
                        </Typography>
                        <Box>
                            {donations.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>7.2. Recurring Payments</h5>
                        </Typography>
                        <Box>
                            {recurringPayments.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>8. Intellectual Property</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>8.1. Ownership</h6>
                        </Typography>
                        <Box>
                            {ownership.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>8.2. License Terms</h5>
                        </Typography>
                        <Typography gutterBottom>
                            <p>Users may not:</p>
                        </Typography>
                        <Box>
                            {licenseTerms.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>9. User Content and Conduct</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>9.1. Content Standards</h6>
                        </Typography>
                        <Box>
                            {contentStandards.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>9.2. Monitoring and Removal</h5>
                        </Typography>
                        <Box>
                            {monitoringAndRemoval.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>10. Security</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>10.1. Security Measures</h6>
                        </Typography>
                        <Box>
                            {securityMeasures.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>10.2. Breach Notification</h5>
                        </Typography>
                        <Box>
                            {breachNotification.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>11. Service Level Agreement</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>11.1. Availability</h6>
                        </Typography>
                        <Box>
                            {availability.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>11.2. Support</h5>
                        </Typography>
                        <Box>
                            {support.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>12. Legal Framework</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>12.1. Liability Limitations</h6>
                        </Typography>
                        <Box>
                            {liabilityLimitations.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>12.2. Dispute Resolution</h5>
                        </Typography>
                        <Box>
                            {disputeResolution.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>13. Nonprofit Status</h5>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <h6>13.1. Organization Information</h6>
                        </Typography>
                        <Box>
                            {organizationInformation.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>13.2. Financial Reporting</h5>
                        </Typography>
                        <Box>
                            {financialReporting.map((item, index) => (
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p>{item.description}</p>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>14. Contact Information</h5>
                        </Typography>
                        <Typography display={'flex'} margin={'0.5rem 0'}>
                            <Circle className={styles.circleBox} />
                            <p>General inquiries:<strong><a href="mailto:'pr@voicesforprogress.net.'">pr@voicesforprogress.net</a>.</strong></p>
                        </Typography>

                    </Box>
                    <Box className={styles.box}>
                        <Typography variant="h5" gutterBottom>
                            <h5>15. Amendments</h5>
                        </Typography>
                        <Typography display={'flex'} margin={'0.5rem 0'}>
                            <Circle className={styles.circleBox} />
                            <p>We reserve the right to modify these Terms. Users will be notified of significant changes. Continued use constitutes acceptance of updated Terms.</p>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default TermConditions;
