import React from 'react';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface contributionProps {
    image: string;
    title: string;
    description: string;
    viewButton: string;
    redirectedLink: string;
}

const ContributionCard: React.FC<{ contributionCard: contributionProps }> = ({ contributionCard }) => {
    return (
        <Box className={styles.box}>
            <img src={contributionCard.image} alt="image" />
            <Box className={styles.overlay} />
            <Box className={styles.contents}>
                <Typography className={styles.typo}><h1>{contributionCard.title}</h1></Typography>
                <Typography className={styles.typo}><p>{contributionCard.description}</p></Typography>
                <Box className={styles.btn}>
                    <PrimaryButton redirectLink={contributionCard.redirectedLink} buttonTitle={contributionCard.viewButton} />
                </Box>
            </Box>
        </Box>
    )
}

export default ContributionCard