import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import ContributionCard from '../../molecules/contributionCard/contributionCard';

interface contributionProps {
    image: string;
    title: string;
    description: string;
    viewButton: string;
    redirectedLink: string;
}

interface becomePartProps {
    title: string;
    contributionCard: contributionProps[];
}

const BecomePart: React.FC<becomePartProps> = ({ title, contributionCard }) => {
    const getGridSize = (index: number) => {
        switch (index) {
            case 0:
                return { xs: 12, sm: 12, md: 6 }; // First item - large grid (6 on large screen)
            case 1:
            case 2:
                return { xs: 12, sm: 6, md: 3 }; // Second and third items - smaller grid (3 on large screen)
            case 3:
                return { xs: 12, sm: 12, md: 6 }; // Fourth item - large grid (6 on large screen)
            case 4:
                return { xs: 12, sm: 12, md: 6 }; // Fifth and sixth items - half grid each (6 on large screen)
            case 5:
                return { xs: 12, sm: 12, md: 12 }; // Last item - full grid (12 on large screen)
            default:
                return { xs: 12, sm: 6, md: 4 }; // Default grid size for other items
        }
    };

    return (
        <Box className={styles.box}>
            <Typography variant="h1" className={styles.typo}>
                <h1>{title}</h1>
            </Typography>
            <Grid container spacing={2}>
                {contributionCard.map((item, index) => (
                    <Grid
                        item
                        {...getGridSize(index)}
                        key={index}
                        className={index === 5 ? styles.lastGridItem : ''}
                    >
                        <ContributionCard contributionCard={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BecomePart;
