import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react';
import styles from './styles.module.scss'
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';


interface benifitsData1Props {
    image: string;
    title: string;
    description: string;
    description2?: string;
    noteTitle?: string;
    buttonTitle?: string;
    redirectLink?: string;
}
const BenifitsCard: React.FC<benifitsData1Props> = ({ image, title, description, description2, redirectLink, buttonTitle, noteTitle }) => {
    return (
        <Box className={styles.messageCard}>
            <Grid container spacing={8}>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <img src={image} alt="image-icon" />
                </Grid>
                <Grid item xs={12} lg={6} display={'flex'} className={styles.contentsBox}>
                    <Box className={styles.leftBorder}></Box>
                    <Box className={styles.contents}>
                        <Typography className={styles.typo}><h1>{title}</h1></Typography>
                        <Typography className={styles.typo}><p>{description}</p></Typography>
                        {description2 && (<Typography className={styles.typo}><p>{description2}</p></Typography>)}
                        {noteTitle && (<Typography className={styles.typo}>
                            <h2>{noteTitle}</h2>
                        </Typography>)}
                        {buttonTitle && redirectLink && (<Box>
                            <Box className={styles.btn}>
                                <PrimaryButton buttonTitle={buttonTitle} redirectLink={redirectLink} />
                            </Box>
                        </Box>)}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <img src={image} alt="image-icon" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BenifitsCard;