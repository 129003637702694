import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./styles.module.scss";
import { Box, Container, Grid, Typography } from '@mui/material';
import Otp from '../../components/organisms/otp/otp';

const OtpVerification: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');

        if (email) {
            setEmail(email);
        }
    }, []);

    const handleOtpChange = (newOtp: string) => {
        setOtp(newOtp);
    };

    return (
        <Box className={styles.signUp}>
            <Container maxWidth='xl' className={styles.containerPadding}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6} className={styles.imageBox}>
                        <img src="/assets/images/forgot-banner.png" alt="login-banner" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem 0rem 2rem' }, display: 'grid' }}>
                        <Box className={styles.formBox} sx={{ padding: { xs: '1rem', md: '2rem', lg: '3rem' } }}>
                            <Typography variant="h1" gutterBottom className={styles.typo}>
                                <h1>Verification Code Sent</h1>
                            </Typography>
                            <Typography gutterBottom className={styles.typo}>
                                <p>Your verification code (OTP) has arrived. Use the code below to verify your email.</p>
                            </Typography>
                            {error && <Typography color="error">{error}</Typography>}
                            <form onSubmit={(e) => { e.preventDefault(); }}>
                                <Box>
                                    <Otp
                                        length={4}
                                        separator="-"
                                        value={otp}
                                        onChange={handleOtpChange}
                                        email={email}
                                        source={new URLSearchParams(window.location.search).get('source') || undefined}
                                    />
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default OtpVerification;
