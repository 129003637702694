import React from 'react';
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface collaborationProps {
    image: string;
    title: string;
    linkTitle: string;
    rediredtedLink: string;
}

const CollabCard: React.FC<{ collabCard: collaborationProps }> = ({ collabCard }) => {
    return (
        <Box>
            <Box className={styles.cardBox}>
            <Link to={collabCard.rediredtedLink} className={styles.link}><Box className={styles.imageBox}><img src={collabCard.image} alt="collaboration-img" />
                    <Typography className={styles.typo}><h2><a href={collabCard.rediredtedLink}>{collabCard.linkTitle}</a></h2></Typography>
                </Box>
                <Box className={styles.box}><Typography><h3>{collabCard.title}</h3></Typography></Box>
            </Link>
            </Box>
        </Box>
    )
}

export default CollabCard