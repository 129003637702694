import React from 'react';
import styles from './styles.module.scss';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { OptionInterface } from '../../../interface/adminDashboard';

interface searchAutocompleteProps {
    options: OptionInterface[];
    placeholder: string;
    onChange: (event: React.ChangeEvent<{}>, value: string) => void;
}

const SearchAutocomplete: React.FC<searchAutocompleteProps> = ({ options, placeholder, onChange }) => {
    return (
        <Box className={styles.box}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                getOptionLabel={(option) => option.label || ''}
                onChange={(event, value) => {
                    const selectedValue = value ? value.value : '';
                    onChange(event, selectedValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ margin: '0' }}>
                                    <SearchIcon sx={{ fontSize: '20px' }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            height: '40px',
                            '& .MuiInputBase-root': {
                                height: '40px',
                            },
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default SearchAutocomplete;