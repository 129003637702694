import React from "react";
import styles from "./styles.module.scss";
import { Container, Box } from "@mui/material";
import Banner from "../../components/organisms/banner/banner";
import Mission from "../../components/organisms/mission/mission";
import Vision from "../../components/organisms/vision/vision";
import ChooseUs from "../../components/organisms/chooseUs/chooseUs";
import Partner from "../../components/organisms/partner/partner";
import Legacy from "../../components/organisms/legacy/legacy";
import Collaboration from "../../components/organisms/collaboration/collaboration";
import BecomePart from "../../components/organisms/becomePart/becomePart";
import {
  BannerCard,
  CollaborationInterface,
  ContributionInterface,
  LegacyInterface,
  MissionCardInterface,
  PartnerInterface,
  VisionMissionInterface
} from "../../interface/home";
import homeData from "../../data/home.json";

const Home: React.FC = () => {
  const titleHeader = homeData.titleHeader;
  const bannerCard: BannerCard[] = homeData.bannerCard;
  const bannerTitle = homeData.bannerTitle;
  const bannerDescription = homeData.bannerDescription;
  const bannerDescription2 = homeData.bannerDescription2;
  const becomePartTitle = homeData.becomePartTitle;
  const contributionData: ContributionInterface[] = homeData.contributionData;
  const coreTitle = homeData.coreTitle;
  const coreData: MissionCardInterface[] = homeData.coreData;
  const visionMission: VisionMissionInterface[] = homeData.visionMission;
  const collaborationTitle = homeData.collaborationTitle;
  const collaborationDesc = homeData.collaborationDesc;
  const collaborationData: CollaborationInterface[] = homeData.collaborationData;
  const whyChooseTitle = homeData.whyChooseTitle;
  const whyChooseDesc = homeData.whyChooseDesc;
  const whychooseData: MissionCardInterface[] = homeData.whychooseData;
  const legacy: LegacyInterface[] = homeData.legacy;
  const partnerTitle = homeData.partnerTitle;
  const partnerDesc = homeData.partnerDesc;
  const ourPartner: PartnerInterface[] = homeData.ourPartner;

  return (
    <>
      <Box className={styles.home}>
        <Container maxWidth="xl" sx={{ padding: { xs: "0rem", sm: "1.5rem", lg: "6.5rem 5.5rem" } }}>
          <Banner title={bannerTitle} description={bannerDescription}
            description2={bannerDescription2} titleHeader={titleHeader}
            cardProps={bannerCard} />
        </Container>
      </Box>

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5rem 0 5rem" } }}
        >
          <BecomePart title={becomePartTitle} contributionCard={contributionData} />
        </Container>
      </Box>

      <Box className={styles.coreBlock}>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "2rem", lg: "3rem 5.5rem", textAlign: 'center' } }}
        >
          <Mission title={coreTitle} missionCard={coreData} />
        </Container>
      </Box>

      <Box className={styles.visionBlock} sx={{ padding: { xs: '0 1.5rem', sm: '0' } }}>
        <Container maxWidth="xl" className={styles.containerPadding}>
          {visionMission.map((item, index) => (
            <div key={index}>
              <Vision
                image={item.image}
                title={item.title}
                desc={item.desc}
                buttonTitle={item.buttonTitle}
                redirectLink={item.redirectLink}
              />
            </div>
          ))}
        </Container>
      </Box>

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem" } }}
        >
          <Collaboration title={collaborationTitle} description={collaborationDesc} collabCard={collaborationData} />
        </Container>
      </Box>

      <Box className={styles.whyChooseBg}>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "2rem 1.5rem 0 1.5rem", lg: "3rem 5.5rem" }, textAlign: 'center' }}
        >
          <ChooseUs title={whyChooseTitle} description={whyChooseDesc} chooseCardProps={whychooseData} />
        </Container>
      </Box>

      <Box className={styles.legacy}>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem" } }}
        >
          <Legacy title={legacy[0].title} projects={legacy[0].projects} />
        </Container>
      </Box>

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "1.5rem", lg: "3rem 5.5rem" }, textAlign: 'center' }}
        >
          <Partner title={partnerTitle} description={partnerDesc} partnerTitle={ourPartner} />
        </Container>
      </Box>
    </>
  );
};

export default Home;
