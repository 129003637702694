import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

interface locationProps {
    title: string;
    icon: string;
    desc: string;
    linkTitle: string;
    redirectLink: string;
    image: string;
}

const LocationCard: React.FC<locationProps> = ({ title, icon, desc, linkTitle, redirectLink, image }) => {
    return (
        <Box className={styles.location}>
            <Typography className={styles.typo}><h1>{title}</h1></Typography>
            <Box className={styles.titleBox}>
                <img src={icon} alt="map" />
                <Typography className={styles.typo}><p>{desc}</p></Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5} className={`${styles.typo} ${styles.imgBox}`}>
                    <a href={redirectLink}>{linkTitle}</a>
                </Grid>
                <Grid item xs={12} sm={7} className={styles.imgBox}><img src={image} alt="image" /></Grid>
            </Grid>
        </Box>
    )
}

export default LocationCard;