import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Divider,
    TextField,
    InputAdornment,
} from '@mui/material';
import styles from './styles.module.scss';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SidebarMenu from '../../components/organisms/sidebarMenu/sidebarMenu';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { OptionInterface, Projects, OverView, CricularButton, CollaborationTableHeader, taskTableRows, priorityTableRows, CollaborationTableRow, teamMemberTableRow, contractsTableRow } from '../../interface/adminDashboard';
import dashboardData from '../../data/adminDashboard.json';
import IconButton from '../../components/atoms/iconButton/iconButton';
import ProjectCard from '../../components/organisms/projectCard/projectCard';
import TitleText from '../../components/molecules/titleText/titleText';
import LinkableTable from '../../components/organisms/linkableTable/linkableTable';
import SearchAutocomplete from '../../components/atoms/searchAutocomplete/searchAutocomplete';
import DashboardHeaderAction from '../../components/molecules/dashboardHeaderAction/dashboardHeaderAction';
import CustomTable from '../../components/organisms/CustomeTable/CustomTable';
import apiService from '../../service/apiService';
import { ALL_PROJECT, DROPDOWN_FILTER, GRANT_COLLABORATION, PRIORITY_TABLE, TASK_TABLE, TEAM_CONTRACTS, TEAM_MEMBER } from '../../utils/constants';
import { AxiosResponse } from 'axios';
import ExpandableTable from '../../components/organisms/expandableTable/expandableTable';
import ExpandableTeamTable from '../../components/organisms/expandableTeamTable/expandableTeamTable';
import ExpandableDataTable from '../../components/organisms/expandableTeamTable/expandableTeamTable';
import ExpandCollapseTable from '../../components/templates/expandCollapseTable/expandCollapseTable';

export type SidebarItem = 'Home' | 'Project' | 'Contract' | 'Events' | 'Profile' | 'Calendar' | 'Notifications' | 'Settings';

const workingGroupBtn: CricularButton[] = dashboardData.workingButton;
const dashboardButton = dashboardData.dashboardButton;
const overview: OverView[] = dashboardData.overView;
const projectMilstone = dashboardData.projectsMilstone;
const headers: CollaborationTableHeader[] = dashboardData.tableData.headers;
const priorityHeader: CollaborationTableHeader[] = dashboardData.topPriority.headers;
const grantCollabHeader: CollaborationTableHeader[] = dashboardData.collaborationTable.headers;
const teamMemberHeader: CollaborationTableHeader[] = dashboardData.tableTeamMemberData.headers;
const teamContractsHeader: CollaborationTableHeader[] = dashboardData.tableContractsData.headers;

const AdminDashboard: React.FC = () => {
    const [selectedItem, setSelectedItem] = useState<SidebarItem>('Home');
    const [activeBtn, setActiveBtn] = useState(1);
    const [projectsData, setProjectsData] = useState<Projects[]>([]);
    const [optionsData, setOptionsData] = useState<Record<string, OptionInterface[]>>({});
    const [filteredProjects, setFilteredProjects] = useState<Projects[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string }>({});
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [taskTableData, setTaskTableData] = useState<taskTableRows[]>([])
    const [priority, setPriority] = useState<priorityTableRows[]>([]);
    const [grantCollaboration, setGrantCollaboration] = useState<CollaborationTableRow[]>([]);
    const [project_id, setProject_id] = useState("");
    const [teamMember, setTeamMember] = useState<teamMemberTableRow[]>([]);
    const [teamContracts, setTeamContracts] = useState<contractsTableRow[]>([]);
    const [isTeamMemberExpanded, setTeamMemberExpanded] = useState(false);
    const [isTeamContractExpanded, setTeamContractExpanded] = useState(true);
    // const [tRows, setTRows] = useState<TableRows[]>([]);
    const fields = ['nation', 'region', 'state', 'city', 'county'];

    const sidebarIcons = {
        Home: <HomeOutlinedIcon />,
        Project: <ArticleOutlinedIcon />,
        Contract: <InsertDriveFileOutlinedIcon />,
        Events: <LocalActivityOutlinedIcon />,
        Profile: <AccountCircleOutlinedIcon />,
        Calendar: <CalendarMonthOutlinedIcon />,
        Notifications: <NotificationsNoneOutlinedIcon />,
        Settings: <SettingsOutlinedIcon />,
    };

    const onSidebarClick = (item: SidebarItem) => {
        setSelectedItem(item);
    };

    const handleSpanClick = (id: number) => {
        setActiveBtn(id);
    };

    const handleAddClick = () => { };
    const onDashboard = () => { };

    interface DropdownResponse {
        data: string[];
        field: string;
        filter: string;
    }

    const fetchDropdownOptions = async (field: string) => {
        try {
            const response: AxiosResponse<DropdownResponse> = await apiService.postApi(DROPDOWN_FILTER, {
                fields: field,
                filter: '',
            });

            if (response && response.data) {
                const { data, field: fieldName } = response.data;
                const transformedOptions = data.map((item: string, index: number) => ({
                    id: `${fieldName}-${index}`,
                    label: item,
                    value: item,
                }));
                setOptionsData((prev: any) => {
                    const updatedData = { ...prev, [fieldName]: transformedOptions };
                    return updatedData;
                });
            } else {
                alert(`Failed to fetch ${field} options.`);
            }
        } catch (error) {
            console.error(`Error fetching ${field} options:`, error);
        }
    };

    const fetchAllProjectOptions = async () => {
        try {
            const response = await apiService.postApi(ALL_PROJECT + '/1/100', {});
            if (response && response.data) {
                const projects = response.data.data;
                setProjectsData(projects);
                if (projects.length > 0) {
                    setSelectedProject(projects[0]);
                    fetchPriorityData(projects[0].id);
                    fetchGrantCollaboration(projects[0].id);
                    fetchTaskTableData(projects[0].id);
                    fetchTeamMember(projects[0].id);
                    fetchContracts(projects[0].id);
                }
            } else {
                alert('Failed to fetch project options.');
            }
        } catch (error) { }
    };

    const fetchTaskTableData = async (project_id: string) => {
        console.log("taskTableData>", project_id);
        try {
            const response = await apiService.postApi(TASK_TABLE, {
                items_per_page: 10,
                page_number: 1,
                project_id: project_id
            });
            if (response && response.data) {
                setTaskTableData(response.data.data);
            } else {
                alert('Failed to fetch Data.');
            }
        } catch (error) { }
    }

    const fetchPriorityData = async (project_id: string) => {
        try {
            const response = await apiService.getApi(PRIORITY_TABLE + `${project_id}`, {});
            if (response && response.data) {
                setPriority(response.data.data);
            } else {
                console.log("Failed to fetch Data.")
            }
        } catch { }
    }

    const fetchGrantCollaboration = async (project_id: string) => {
        try {
            const response = await apiService.getApi(GRANT_COLLABORATION + `${project_id}`, {});
            console.log("response", response);
            if (response && response.data) {
                setGrantCollaboration(response.data.data);
            } else {

            }
        } catch { }
    }

    const fetchTeamMember = async (project_id: string) => {
        try {
            const response = await apiService.getApi(TEAM_MEMBER + `${project_id}`, {});
            if (response && response.data) {
                setTeamMember(response.data.data);
            } else {

            }
        } catch { }
    }
    const fetchContracts = async (project_id: string) => {
        try {
            const response = await apiService.getApi(TEAM_CONTRACTS + `1/10/${project_id}`, {});
            if (response && response.data) {
                setTeamContracts(response.data.data);
            } else {

            }
        } catch { }
    }

    useEffect(() => {
        fields.forEach(field => {
            fetchDropdownOptions(field);
        });
        fetchAllProjectOptions();
    }, []);

    useEffect(() => {
        // console.log('Selected Filters:', selectedFilters);
        // console.log('Projects Data:', projectsData);

        if (Object.values(selectedFilters).every(value => !value)) {
            setFilteredProjects(projectsData);
        } else {
            const filtered = projectsData.filter((project) => {
                return Object.entries(selectedFilters).every(([key, value]) => {
                    if (!value) return true;

                    if (key in project) {
                        const projectValue = project[key as keyof typeof project];
                        if (typeof projectValue === 'string') {
                            return projectValue.toLowerCase().includes(value.toLowerCase());
                        }
                        if (typeof projectValue === 'number') {
                            return projectValue.toString().includes(value);
                        }
                    }
                    return false;
                });
            });
            setFilteredProjects(filtered);
        }
    }, [selectedFilters, projectsData]);

    const handleFilterChange = (field: string, value: string) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCardClick = (index: number) => {
        const selected = projectsData[index];
        setSelectedProject(selected);
        fetchPriorityData(selected.id);
        fetchGrantCollaboration(selected.id);
        fetchTaskTableData(selected.id);
        fetchTeamMember(selected.id);
        fetchContracts(selected.id);
        setSelectedIndex(index);
        setProject_id(projectsData[index]?.id)
    };
    const getIcon = (iconName: string) => {
        if (iconName === 'CallMadeOutlinedIcon') {
            return <CallMadeOutlinedIcon sx={{ width: '1.25rem' }} />;
        }
        return null;
    };
    const selectedIndexProject = projectsData[selectedIndex];

    const overallProgress = [{ name: 'Completed', value: 15 }, { name: 'Progress', value: 65 }, { name: 'Remaining', value: 20 }];
    const COLORS = ['#103055', '#CDDDEE', '#1770D9'];
    const address = '123 Main Street, Kingston, NY';

    const handleEditClick = () => { };

    const toggleTeamMemberExpand = () => {
        setTeamMemberExpanded((prev) => !prev);
        setTeamContractExpanded(false);
    };

    const toggleTeamContractsExpand = () => {
        setTeamContractExpanded((prev) => !prev);
        setTeamMemberExpanded(false);
    };

    return (
        <Box className={styles.admin}>
            <Grid container>
                <Grid item xs={2} className={styles.sidebarContents}>
                    <SidebarMenu
                        selectedItem={selectedItem}
                        sidebarIcons={sidebarIcons}
                        onSidebarClick={onSidebarClick}
                    />
                </Grid>
                <Grid item xs={10} paddingLeft={'1rem'}>
                    {selectedItem === 'Home' && (<Box className={styles.contents}>
                        <Box className={styles.navbar}>
                            <Box>
                                <Typography variant="h3" color='#2a2a2a'>
                                    <h3>Working Groups Allover</h3>
                                </Typography>
                                <Typography variant="h3">
                                    <h3><span>123 Main Street, Kingston, NY</span></h3>
                                </Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'grid', md: 'flex' }, alignItems: { md: 'baseline' } }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Search"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        sx: { height: '40px', border: '1px solid #D5D7DA' }
                                    }}
                                    sx={{ margin: { xs: '0 0 1rem 0', md: '0 1rem 0 0' } }}
                                />
                                <Box className={styles.addBtn}>
                                    <IconButton
                                        buttonTitle={dashboardButton.buttonTitle}
                                        icon={getIcon(dashboardButton.icon)}
                                        onClick={onDashboard}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <img width={'100%'} src="/assets/images/home-map.png" alt="map" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>)}
                    {selectedItem === 'Project' && (<Box className={styles.contents}>
                        <Typography className={styles.navbar} variant="h6" color='#2a2a2a'>
                            <h6><span>{selectedItem} Details</span></h6>
                        </Typography>
                        <Box mb={2}>
                            <DashboardHeaderAction
                                selectedItem={selectedItem}
                                address={address}
                                icon="/assets/images/edit.png"
                                onIconClick={handleEditClick}
                                buttons={workingGroupBtn}
                                activeBtn={activeBtn}
                                onButtonClick={handleSpanClick}
                            />
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <img width={'100%'} src="/assets/images/project-group-map.png" alt="map" />
                                </Box>
                                <Grid container spacing={2} mt={0}>
                                    {fields.map((field, index) => (
                                        <Grid item xs={12} md={6} lg={2.4} key={index}>
                                            <SearchAutocomplete
                                                options={optionsData[field] || []}
                                                placeholder={`Select ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                                onChange={(event, value) => handleFilterChange(field, value)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Divider sx={{ background: '#CEDDED', margin: '1rem 0' }} />
                                <Box overflow={'auto'} pb={2}>
                                    <Grid container spacing={4} sx={{ flexWrap: 'nowrap' }}>
                                        {filteredProjects.map((project, index) => (
                                            <Grid
                                                key={project.id}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                className={`${styles.projects} ${selectedProject?.id === project.id ? styles.selectedCard : ''
                                                    }`}
                                                onClick={() => handleCardClick(index)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <ProjectCard
                                                    id={project.id}
                                                    name={project.name}
                                                    people_count={project.people_count}
                                                    donations={project.donations}
                                                    goal_percentage={project.goal_percentage}
                                                    description={project.description}
                                                    project_image={project.project_image}
                                                    project_links={project.project_links}
                                                    goal_type={project.goal_type}
                                                    startdate_home_page={project.startdate_home_page}
                                                    state={project.state}
                                                    city={project.city}
                                                    national={project.national}
                                                    nation={project.nation}
                                                    start_date={project.start_date}
                                                    borderColor={
                                                        selectedProject?.id === project.id ? '2px solid #73ACEF' : '1px solid #C1CEDB'
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                {selectedIndexProject && (
                                    <Box className={styles.overview} pt={2}>
                                        {overview.map((item) => (
                                            <TitleText
                                                key={item.title}
                                                title={item.title}
                                                description={selectedIndexProject.description}
                                            />
                                        ))}
                                    </Box>
                                )}

                                <Box>
                                    {projectMilstone.map((item) => (<TitleText title={item.title} />))}
                                </Box>

                                <Box>
                                    <img width={'100%'} src="/assets/images/project-milstone.png" alt="graph" />
                                </Box>

                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={7.5}>
                                            <Box className={styles.navbar} width={'100%'} mt={4}>
                                                <Typography variant="h3"><h3>Top Priorities</h3></Typography>
                                            </Box>
                                            {priority?.length > 0 ? (
                                                <CustomTable
                                                    headers={priorityHeader}
                                                    rows={priority}
                                                    onAddClick={handleAddClick}
                                                    hideEndDate={true}
                                                />
                                            ) : (
                                                <Typography variant="body1" color="textSecondary">
                                                    No priorities data available for the selected project.
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} lg={4.5}>
                                            <Box className={styles.navbar} width={'100%'} mt={4}>
                                                <Typography variant="h3"><h3 style={{ color: '#103055' }}>EPA Grant Collaboration (BGN)</h3></Typography>
                                            </Box>
                                            {grantCollaboration?.length > 0 ? (
                                                <LinkableTable headers={grantCollabHeader} rows={grantCollaboration} />
                                            ) : (
                                                <Typography variant="body1" color="textSecondary">
                                                    No grant collaboration data available for the selected projects
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box className={styles.navbar} width={'100%'} mt={4}>
                                            <Typography variant="h3"><h3>Task List</h3></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {taskTableData?.length > 0 ? (<CustomTable
                                            headers={headers}
                                            rows={taskTableData}
                                            onAddClick={handleAddClick}
                                        />) : (
                                            <Typography variant="body1" color="textSecondary">
                                                No task list data available for the selected projects
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} margin={'2rem -1rem'}>
                                    <Grid item xs={isTeamMemberExpanded ? 9 : 3}>
                                        {teamMember?.length > 0 ? (
                                            <ExpandableTable
                                                headers={teamMemberHeader}
                                                rows={teamMember}
                                                isExpanded={isTeamMemberExpanded}
                                                onToggleExpand={toggleTeamMemberExpand}
                                            />
                                        ) : (
                                            <Typography variant="body1" color="textSecondary">
                                                No member list data available for the selected projects
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={isTeamContractExpanded ? 9 : 3}>
                                        {teamContracts?.length > 0 ? (
                                            <ExpandableTeamTable
                                                headers={teamContractsHeader}
                                                rows={teamContracts}
                                                isExpanded={isTeamContractExpanded}
                                                onToggleExpand={toggleTeamContractsExpand}
                                            />
                                        ) : (
                                            <Typography variant="body1" color="textSecondary">
                                                No contracts list data available for the selected projects
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>)}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;