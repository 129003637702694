import React from 'react';
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material';

interface comunityProps {
    title:string;
    linkTitle:string;
}

const CommunityCard:React.FC<{comunitydata:comunityProps}> =({comunitydata}) => {
  return (
    <Box className={styles.community}>
        <Typography className={styles.typo}><h2>{comunitydata.title}</h2></Typography>
        <Typography className={styles.typo}><p>{comunitydata.linkTitle}</p></Typography>
    </Box>
  )
}

export default CommunityCard;