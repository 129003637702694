import React from 'react';
import styles from './styles.module.scss';
import { Box, Container, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {
    PrivacyPolicyDefinition,
    Data,
    DataItem
} from '../../interface/privacyPolicy';
import privacyPolicyData from '../../data/privacyPolicy.json';

const PrivacyPolicy: React.FC = () => {

    const definitions: PrivacyPolicyDefinition[] = privacyPolicyData.definitions;
    const personalData: Data[] = privacyPolicyData.personal;
    const usageData: Data[] = privacyPolicyData.usage;
    const useYourPersonalData: Data[] = privacyPolicyData.useYourPersonal;
    const sharingDesclosure: DataItem[] = privacyPolicyData.sharingDesclosure;

    return (
        <Container maxWidth="xl" className={styles.privacy}>
            <Box sx={{ padding: { xs: '1.5rem', lg: '0.5rem 6rem' } }}>
                <Typography variant="h3" textAlign={'center'} className={styles.box}>
                    <h3>Privacy Policy</h3>
                </Typography>
                <Typography variant="h5" textAlign={'center'} my={4} sx={{ textDecoration: 'underline' }}>
                    <h5>Last updated on November 15, 2024</h5>
                </Typography>
                <Typography my={4} textAlign={'center'}>
                    <p>This Privacy Policy explains our policies regarding the collection, use, and disclosure of your information when using our website, along with your privacy rights and the legal protections that apply. By using our Service, you agree to the collection and use of information as outlined in this Privacy Policy.</p>
                </Typography>
                <Typography variant="h5">
                    <h5>Interpretation and Definitions</h5>
                </Typography>
                <Typography variant="h6">
                    <h6>Interpretation</h6>
                </Typography>
                <Typography>
                    <p>Terms with capitalized initial letters have meanings defined under specific conditions in this section. These definitions apply whether terms appear in singular or plural form.</p>
                </Typography>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Definitions</h5>
                    </Typography>
                    <Box>
                        {definitions.map((item, index) => (
                            <Box>
                                <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                    <Circle className={styles.circleBox} />
                                    <p><strong>{item.title}</strong>{item.description} <a href="/">{item?.link}</a></p>
                                </Typography>

                                <Box display={'block'} flexDirection="column" marginLeft={3}>
                                    {item.data?.map((data, dataIndex) => (
                                        <Box key={dataIndex} display="flex" margin={'0.5rem 0'}>
                                            <CircleOutlinedIcon className={styles.circleBox} />
                                            <p style={{ lineHeight: '1.5rem' }}><strong>{data.title}</strong>{data.description}</p>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>

                        ))}
                    </Box>
                </Box>

                <Typography variant="h5" my={4} gutterBottom>
                    <h5>Collecting and Using Your Personal Data</h5>
                </Typography>
                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Types of Data Collected</h5>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <h6>Personal Data</h6>
                    </Typography>
                    <Typography gutterBottom>
                        <p>We may ask you to provide certain personally identifiable information (PII) to enhance your experience or fulfill requests. This may include:</p>
                    </Typography>
                    <Box>
                        {personalData.map((item, index) => (
                            <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                <Circle className={styles.circleBox} />
                                <p>{item.description}</p>
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Usage Data</h5>
                    </Typography>
                    <Typography gutterBottom>
                        <p>Usage Data is collected automatically when using the Service. This may include:</p>
                    </Typography>
                    <Box>
                        {usageData.map((item, index) => (
                            <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                <Circle className={styles.circleBox} />
                                <p>{item.description}</p>
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Information from Third-Party Social Media Services</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>If you register or log in through social media, we may collect Personal Data from those platforms (e.g., name, email, profile picture). By connecting through a social media platform, you give us permission to use, store, and share this information as per this Privacy Policy.</p>
                    </Typography>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Tracking Technologies and Cookies</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>We use Cookies and similar tracking technologies to monitor usage and improve the Service. You may set your browser to reject Cookies, but some features of our Service may not function correctly without them.</p>
                    </Typography>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Use of Your Personal Data</h5>
                    </Typography>
                    <Typography gutterBottom>
                        <p>We may use your data to:</p>
                    </Typography>
                    <Box>
                        {useYourPersonalData.map((item, index) => (
                            <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                <Circle className={styles.circleBox} />
                                <p>{item.description}</p>
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Data Sharing and Disclosure</h5>
                    </Typography>
                    <Typography gutterBottom>
                        <p>We may share your data:</p>
                    </Typography>
                    <Box>
                        {sharingDesclosure.map((item, index) => (
                            <Typography key={index} display={'flex'} margin={'0.5rem 0'}>
                                <Circle className={styles.circleBox} />
                                <p>{item.description}</p>
                            </Typography>
                        ))}
                    </Box>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Data Retention and Security</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>We retain Personal Data as long as necessary to fulfill the purposes outlined in this Privacy Policy and comply with legal obligations. While we strive for a secure environment, no method of electronic storage can guarantee absolute security.</p>
                    </Typography>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Children’s Privacy</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>Our Service is not intended for children under 13. If we discover data from a child under 13, we will delete it promptly.</p>
                    </Typography>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Policy Updates</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>We may update this Privacy Policy from time to time. Changes will be posted here with an updated date, and you may receive notifications through the Service or email.</p>
                    </Typography>
                </Box>

                <Box className={styles.box}>
                    <Typography variant="h5" gutterBottom>
                        <h5>Contact Us</h5>
                    </Typography>
                    <Typography display={'flex'} margin={'0.5rem 0'}>
                        <Circle className={styles.circleBox} />
                        <p>For questions, please email<strong><a href="mailto:'pr@voicesforprogress.net'">pr@voicesforprogress.net</a>.</strong></p>
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default PrivacyPolicy