import React from 'react';
import styles from './styles.module.scss'
import { Box, Container, Grid, Typography } from '@mui/material';
import HeaderBanner from '../../components/organisms/header-Banner/header-banner';
import MissionCard from '../../components/molecules/missionCard/missionCard';
import MessageCard1 from '../../components/organisms/messageCard1/messageCard1';
import MessageCard2 from '../../components/organisms/messageCard2/messageCard2';
import {
    BannerHeader,
    MessageData1Interface,
    MessageData2Interface,
    VolunteerUsInterface
} from '../../interface/volunteers';
import vulunteersData from '../../data/volunteers.json';

const headerBanner: BannerHeader[] = vulunteersData.banner;
const volunteerTitle = vulunteersData.volunteerTitle;
const volunteers: VolunteerUsInterface[] = vulunteersData.volunteers;
const message: MessageData1Interface[] = vulunteersData.message;
const opportunities: MessageData2Interface[] = vulunteersData.opportunities;

const Vulunteers: React.FC = () => {
    return (
        <>
            <Box className={styles.enterpreneurs}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    {headerBanner.map((item) => (<HeaderBanner
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        infoButton={item.infoButton}
                        redirectedLink={item.redirectedLink}
                        centerData={item.centerData}
                    />))}
                </Container>
            </Box>

            <Box className={styles.enterpreneurs} id='learn'>
                <Container maxWidth="xl" sx={{ padding: { xs: "1.5rem", lg: "3rem 5rem 3rem 5rem", textAlign: 'center' } }}>
                    <Typography className={styles.typo}><h1>{volunteerTitle}</h1></Typography>
                    <Grid container spacing={8}>
                        {volunteers.map((item) => (
                            <Grid item xs={6} lg={3} className={styles.voluntBox}><MissionCard key={item.id} missionCardData={item} /></Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <Box className={styles.enterpreneurs}>
                <Container maxWidth="xl" sx={{ padding: { xs: "1.5rem", lg: "1rem 5rem 3rem 4rem" } }}>
                    {message.map((item, index) => (
                        <MessageCard1 key={index}
                            title={item.title}
                            description={item.description}
                            noteTitle={item.noteTitle}
                            image={item.image}
                            buttonTitle={item.buttonTitle}
                            buttonRedirectLnk={item.buttonRedirectLnk}
                        />
                    ))}
                </Container>
            </Box>
            <Box className={styles.enterpreneurs}>
                <Container maxWidth="xl" sx={{ padding: { xs: "1rem 1.5rem 3rem 1.5rem", lg: "1rem 5rem 3rem 4rem" } }}>
                    {opportunities.map((item, index) => (
                        <MessageCard2 key={index}
                            title={item.title}
                            description={item.description}
                            titleLink={item.titleLink}
                            redirectedLink={item.redirectedLink}
                            buttonTitle={item.buttonTitle}
                            image={item.image}
                        />
                    ))}
                </Container>
            </Box>
        </>
    )
}

export default Vulunteers;