import React from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

interface primaryButtonProps {
  buttonTitle: string;
  redirectLink: string;
}

const PrimaryButton: React.FC<primaryButtonProps> = ({ redirectLink, buttonTitle }) => {
  return (
    <Box className={styles.box}>
      <Link to={redirectLink} style={{ textDecoration: 'none' }}>
        <Button className={styles.btn} fullWidth>{buttonTitle}</Button>
      </Link>
    </Box>
  )
}

export default PrimaryButton;