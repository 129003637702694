import React from "react";
import styles from './styles.module.scss'
import { Box } from "@mui/material";

export interface ButtonProps {
    title: string;
    color: string;
    bgColor: string;
    fontSize: string;
    fontWeight: number;
    fontFamily: string;
    padding: string;
    borderRadius?: string;
    border?: string;
    margin?: string
    icon?: string;
    onClick?: () => void;
}

const CustomButton: React.FC<ButtonProps> = ({ title,
    color,
    bgColor,
    fontSize,
    fontWeight,
    fontFamily,
    padding,
    borderRadius,
    border,
    margin,
    icon,
    onClick }) => {

    const style = {
        color,
        backgroundColor: bgColor,
        fontSize,
        fontWeight,
        fontFamily,
        padding,
        borderRadius,
        border,
        margin,
        icon
    };
    return (
        <Box className={styles.box}>
            <button style={style} onClick={onClick}>
                {icon && (<img src={icon} className={styles.icon} alt="icon" />)}{title}
            </button>
        </Box>
    )
};

export default CustomButton;
