import React from 'react';
import styles from './styles.module.scss';
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Grid, Typography } from '@mui/material';

interface LegacyProps {
    title: string;
    projects: {
        title: string;
        image: string;
        year: number;
        description: string;
    }[];
}

const Legacy: React.FC<LegacyProps> = ({ title, projects }) => {
    return (
        <Box>
            <Box>
                <Typography
                    className={styles.mission}
                    textAlign={"center"}
                    marginBottom={"2rem"}
                >
                    <h1>{title}</h1>
                </Typography>
            </Box>

            {/* Desktop view port  */}
            <Box className={styles.opacityBox}>
                {projects.map((project, index) => (
                    <Grid container key={index} sx={{ display: { xs: "none", md: "flex" } }}>
                        {index % 2 === 0 ? (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Grid item md={9} className={styles.imgBox}>
                                        <img src={project.image} alt="report-image" />
                                        <Box className={styles.overlay}></Box>
                                    </Grid>
                                    <Grid item md={9}>
                                        <Box sx={{ paddingLeft: "2rem" }} className={styles.xlView}>
                                            <Typography
                                                className={styles.visionTypo}
                                                sx={{ marginTop: "-3.5rem" }}
                                            >
                                                <h1>{project.year}</h1>
                                            </Typography>
                                            <Typography className={styles.visionTypo}>
                                                <p>{project.description}</p>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ borderLeft: { md: "2px solid #D7D7D7" } }}>
                                    <Box className={styles.legacyIndicator}>
                                        <CircleIcon sx={{ margin: "0 0.5rem 0 -13px", color: "#1770D9" }} />
                                        <Typography>
                                            <h1>{project.title}</h1>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Box className={styles.legacyIndicator} sx={{ textAlign: "right" }}>
                                        <Typography sx={{ width: "100%" }}>
                                            <h1>{project.title}</h1>
                                        </Typography>
                                        <CircleIcon sx={{ margin: "0 -13px 0 0.75rem", color: "#1770D9", zIndex: 1 }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginLeft: "auto", position: "relative", borderLeft: { md: "2px solid #D7D7D7" } }}>
                                    <Grid item md={9} sx={{ marginLeft: "auto" }} className={styles.imgBox}>
                                        <img src={project.image} alt="report-image" />
                                        <Box className={styles.overlay}></Box>
                                    </Grid>
                                    <Grid item md={9} sx={{ marginLeft: "auto" }} className={styles.xlView}>
                                        <Box sx={{ paddingLeft: "2rem" }}>
                                            <Typography
                                                className={styles.visionTypo}
                                                sx={{ marginTop: "-3.5rem" }}
                                            >
                                                <h1>{project.year}</h1>
                                            </Typography>
                                            <Typography className={styles.visionTypo}>
                                                <p>{project.description}</p>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ))}
            </Box>

            {/* Mobile or Gadgets view port  */}
            <Box className={styles.opacityBox}>
                {projects.map((project, index) => (
                    <Grid
                        key={index}
                        container
                        sx={{
                            display: { xs: "flex", md: "none" },
                            borderLeft: { xs: "2px solid #D7D7D7" },
                        }}>
                        <Grid item xs={12} md={6}>
                            <Box className={styles.legacyIndicatorMobile}>
                                <CircleIcon
                                    sx={{ margin: "0 0.5rem 0 -13px", color: "#1770D9" }}
                                />
                                <Typography>
                                    <h1>{project.title}</h1>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} sm={6} sx={{ margin: "0 auto" }}>
                                <Box className={styles.legacyImgResponsive}>
                                    <img src={project.image} alt="report-image" />
                                    <Box className={styles.overlay}></Box>
                                </Box>
                                <Box sx={{ paddingLeft: "2rem" }}>
                                    <Typography
                                        className={styles.visionTypo}
                                        sx={{ marginTop: { xs: "-1.25rem", sm: "-2.25rem" } }}
                                    >
                                        <h1>{project.year}</h1>
                                    </Typography>
                                    <Typography className={styles.visionTypo}>
                                        <p>
                                            {project.description}
                                        </p>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
}

export default Legacy;