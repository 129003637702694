import React from 'react';
import styles from './styles.module.scss'
import { Box, Button, Grid, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface messageData2Props {
    image: string;
    title: string;
    description?: string;
    titleLink: string;
    buttonTitle: string;
    redirectedLink: string
}

const MessageCard2: React.FC<messageData2Props> = ({ image, title, description, titleLink, buttonTitle, redirectedLink }) => {
    return (
        <Box className={styles.messageCard}>
            <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                    <img src={image} alt="image-icon" />
                </Grid>
                <Grid item xs={12} lg={6} display={'flex'}>
                    <Box className={styles.leftBorder}></Box>
                    <Box className={styles.contents}>
                        <Typography className={styles.typo}><h1>{title}</h1></Typography>
                        <Typography className={styles.typo}><h5>{description}</h5></Typography>
                        <Typography className={styles.typo}><p>{titleLink}</p></Typography>
                        <Box className={styles.btn}>
                            <PrimaryButton buttonTitle={buttonTitle} redirectLink={redirectedLink} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MessageCard2;