import React from 'react';
import styles from './styles.module.scss';
import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';

interface IconButtonProps {
    buttonTitle: string;
    icon: ReactNode;
    onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ buttonTitle, icon, onClick }) => {
    return (
        <Box className={styles.box}>
            <Button variant="contained" className={styles.btn} onClick={onClick}>
                {buttonTitle}
                {icon && <span className={styles.icon}>{icon}</span>}
            </Button>
        </Box>
    );
};

export default IconButton;
