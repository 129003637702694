import React from 'react';
import styles from './styles.module.scss'
import { Box, Container, Grid, Typography } from '@mui/material';
import CommunityCard from '../../components/molecules/community-card/community-card';
import EmpowerCard from '../../components/molecules/empowerCard/empowerCard';
import OppurtunityCard from '../../components/organisms/oppurtunity-Card/oppurtunityCard';
import BenifitsCard from '../../components/organisms/benifitsCard/benifitsCard';
import CollaborationBanner from '../../components/organisms/collborationBanner/collaborationBanner';
import {
    BenifitsInterface,
    ComunityInterface,
    EmpowerInterface,
    OppurtunityCardInterface,
    WorkingBannerInterface
} from '../../interface/workingGroup';
import workingGroupData from '../../data/workingGroup.json';

const workingBanner: WorkingBannerInterface[] = workingGroupData.banner;
const oppurtunityCardData: OppurtunityCardInterface[] = workingGroupData.oppurtunity;
const empPowerTitle = workingGroupData.empPowerTitle;
const empowerCardData: EmpowerInterface[] = workingGroupData.empower;
const benifitsData: BenifitsInterface[] = workingGroupData.benifits;
const comunityCardData: ComunityInterface[] = workingGroupData.community;

const WorkingGroup: React.FC = () => {
    return (
        <>
            <Box className={styles.eBanner} sx={{ padding: { xs: '1.5rem', md: '3rem 4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Box>
                        {workingBanner.map((item, index) => (
                            <div key={index}>
                                <CollaborationBanner key={index}
                                    title={item.title}
                                    colorTitle={item.colorTitle}
                                    image={item.image}
                                />
                            </div>
                        ))}
                    </Box>
                </Container>
            </Box>

            <Box sx={{ padding: { xs: '1.5rem', lg: '0' }, marginTop: { xs: '0', sm: '4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Box>
                        {oppurtunityCardData.map((item, index) => (
                            <div key={index}>
                                <OppurtunityCard
                                    image={item.image}
                                    title={item.title}
                                    desc={item.desc}
                                    desc2={item.desc2}
                                />
                            </div>
                        ))}
                    </Box>
                </Container>
            </Box>
            <Box sx={{ padding: { xs: '1.5rem', md: '2rem 4rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    <Typography className={styles.typo}><h1>{empPowerTitle}</h1></Typography>
                    <Grid container spacing={4} marginTop={'0rem'}>
                        {empowerCardData.map((item, index) => (<Grid item xs={12} lg={6}>
                            <EmpowerCard key={index} empowerCard={item}></EmpowerCard>
                        </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <Box>
                <Container maxWidth="xl" sx={{ padding: { xs: "2rem 0", lg: "1rem 4rem 3rem 4rem" } }}>
                    {benifitsData.map((item, index) => (
                        <BenifitsCard key={index}
                            title={item.title}
                            description={item.description}
                            image={item.image}
                        />
                    ))}
                </Container>
            </Box>

            <Box className={styles.events} sx={{ padding: { xs: '1.5rem ', md: '2rem' }, marginBottom: { lg: '5rem' } }}>
                <Container maxWidth="xl" className={styles.containerPadding}>
                    {comunityCardData.map((item, index) => (
                        <CommunityCard key={index} comunitydata={item}></CommunityCard>
                    ))}
                </Container>
            </Box>
        </>
    )
}

export default WorkingGroup;