import React from 'react';
import styles from './styles.module.scss'
import { Box, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface engageConnectCardProps {
    title: string;
    icon?: string;
    learnMoreText?: string;
    description: string;
    buttonTitle: string;
    buttonRedirectLink: string;
}

const EngageConnectCard: React.FC<engageConnectCardProps> = ({ title, icon, learnMoreText, description, buttonTitle, buttonRedirectLink }) => {
    return (
        <Box className={styles.engage}>
            <Typography><h2>{title} {icon && (<img src={icon} alt="icon" />)}</h2></Typography>
            {learnMoreText && (<Typography mt={'0.5rem'}><small>{learnMoreText}</small></Typography>)}
            <Typography sx={{ mt: '0.5rem', mb: '1rem' }}><p>{description}</p></Typography>
            <Box className={styles.btn}>
                <PrimaryButton buttonTitle={buttonTitle} redirectLink={buttonRedirectLink} />
            </Box>
        </Box>
    )
}

export default EngageConnectCard;