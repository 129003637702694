import React from 'react';
import styles from './styles.module.scss';
import { Box, Typography } from '@mui/material';

interface titleText {
    title: string;
    description?: string;
}

const TitleText: React.FC<titleText> = ({ title, description }) => {
    return (
        <Box className={styles.title}>
            <Typography><h6>{title}</h6></Typography>
            <Typography><p>{description}</p></Typography>
        </Box>
    )
}

export default TitleText;