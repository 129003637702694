import React, { useEffect } from 'react';
import './App.module.scss';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import MainLayout from './layouts/main-layouts';
import PublicRoutes from './routes/public.routes';
import ProtectedRoutes from './routes/protected.routes';
import AuthRoutes from './routes/auth.routes';

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <MainLayout>
      <PublicRoutes />
      <ProtectedRoutes />
      <AuthRoutes />
    </MainLayout>
  );
};

export default App;
