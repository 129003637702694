import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignIn from '../pages/signIn/signIn';
import useAuth from '../hooks/useAuth';

const AuthRoutes: React.FC = () => {
  const { signIn } = useAuth();

  return (
    <Routes>
      <Route path="/signin" element={<SignIn onSignin={signIn} />} />
    </Routes>
  );
};

export default AuthRoutes;
