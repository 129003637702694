import { Box } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';

interface CircularButton {
    id: number;
    title: string;
}

interface CircularButtonGroupProps {
    buttons: CircularButton[];
    activeBtn: number;
    onClick: (id: number) => void;
}

const CircularButtonGroup: React.FC<CircularButtonGroupProps> = ({ buttons, activeBtn, onClick }) => {
    return (
        <Box className={styles.flatBtn}>
            {buttons.map((btn) => (
                <span
                    key={btn.id}
                    onClick={() => onClick(btn.id)}
                    style={{
                        backgroundColor: activeBtn === btn.id ? '#FFFFFF' : 'transparent',
                        color: activeBtn === btn.id ? '#1770D9' : '#859CB8',
                        boxShadow: activeBtn === btn.id ? '0px 4px 10px 0px #000D2629' : 'none',
                        borderRadius: activeBtn === btn.id ? '25px' : '0',
                        cursor: 'pointer',
                        padding: '0.8rem 2rem',
                        margin: '0',
                    }}
                >
                    {btn.title}
                </span>
            ))}
        </Box>
    );
};

export default CircularButtonGroup;