import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SIGN_IN } from '../../utils/constants';
import apiService from '../../service/apiService';
import CustomButton from '../../components/atoms/customButton/customButton';
import {
    CredentialResponse,
    GoogleLogin,
    GoogleOAuthProvider,
} from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ButtonProps } from '../../interface/signIn';
import signInData from '../../data/signIn.json';

const facebookButtonProps: ButtonProps = signInData.facebook;
const linkedInButtonProps: ButtonProps = signInData.linkedIn;
const hardToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJhZmYwZGZjLThlNzEtNGQyZi05Mzk3LTljYWIwYmQ2NmRhOSIsIm5hbWUiOiJBbnVzd2FudCBQYW5kZXkiLCJlbWFpbCI6IkFudXN3YXRQQG1heWFkYXRhLmNvbSIsInJvbGVfbmFtZSI6ImFkbWluIiwiZXhwIjoxNzY0NzQ3NjQ4LCJpYXQiOjE3MzMyMTE2NDh9.LFlVV-B69y5twO92QYLKzBEP0-sh_nVNMra1jIctpDA";
interface LoginProps {
    onSignin: (token: string, role: string) => void;
}

const Signin: React.FC<LoginProps> = ({ onSignin }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');

        if (savedEmail) {
            setFormData({ email: savedEmail, password: savedPassword || '' });
            setRememberMe(true);
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(e.target.checked);
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await apiService.postApi(SIGN_IN, {
                email_address: formData.email,
                password: formData.password,
            });

            if (response && response.data) {
                const jwtToken = response.data.data.jwt;
                const role = response.data.data.user_role_id || 'user';
                localStorage.setItem('token', hardToken);

                if (rememberMe) {
                    localStorage.setItem('email', formData.email);
                    localStorage.setItem('password', formData.password);
                } else {
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                }

                onSignin(hardToken, role);
                switch (role.toLowerCase()) {
                    case 'admin':
                        navigate('/admin-dashboard');
                        break;
                    case 'user':
                    default:
                        navigate('/dashboard');
                        break;
                }
                window.location.reload();
            } else {
                alert('Sign in failed');
            }
        } catch (error) {
            alert('Sign in failed');
        }
    };
    const handleGoogleSubmit = async (email: string, password: string, sso: boolean) => {
        try {
            const response = await apiService.postApi(SIGN_IN, {
                email_address: email,
                password: password,
                sso: sso,
            });
            if (response && response.data) {
                const jwtToken = response.data.data.jwt;
                const role = response.data.data.user_role_id || 'user';
                localStorage.setItem('token', hardToken);
                alert(response.data.message);

                onSignin(hardToken, role);
                switch (role.toLowerCase()) {
                    case 'admin':
                        navigate('/admin-dashboard');
                        break;
                    case 'user':
                    default:
                        navigate('/dashboard');
                        break;
                }
                window.location.reload();
            } else {
                alert('Sign in failed');
            }
        } catch (error) {
            alert('Sign in failed');
        }
    };

    const handleGoogleLoginSuccess = async (credentialResponse: CredentialResponse) => {
        try {
            const decodedData: any = credentialResponse?.credential
                ? jwtDecode(credentialResponse?.credential)
                : null;
            await handleGoogleSubmit(decodedData.email, 'google', true);
        } catch (error) {
            alert('Sign in failed');
        }
    };

    return (
        <Box className={styles.signUp}>
            <Container maxWidth="xl" className={styles.containerPadding}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6} className={styles.imageBox}>
                        <img src="/assets/images/login-banner.png" alt="login-banner" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem 0rem 2rem' },
                            display: 'grid',
                        }}
                    >
                        <Box
                            className={styles.formBox}
                            sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem' } }}
                        >
                            <Typography variant="h1" gutterBottom className={styles.typo}>
                                <h1>Login</h1>
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="email" className={styles.label}>
                                        Email
                                    </InputLabel>
                                    <TextField
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        type="email"
                                        fullWidth
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        placeholder="johndoe123@mail.com"
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="password" className={styles.label}>
                                        Password
                                    </InputLabel>
                                    <TextField
                                        id="password"
                                        name="password"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter your password"
                                        className={styles.inputField}
                                    />
                                </Box>
                                <Box
                                    sx={{ mb: '1rem' }}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <FormControlLabel
                                        className={styles.label}
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                onChange={handleRememberMeChange}
                                            />
                                        }
                                        label="Remember Me"
                                    />
                                    <Link href="/forgot-password" className={styles.redirectLink}>
                                        Forgot Password
                                    </Link>
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2, p: 1.5, textTransform: 'capitalize' }}
                                >
                                    Sign In
                                </Button>

                                <Box className={styles.signupOptions}>
                                    <Typography>
                                        <p>or</p>
                                    </Typography>
                                    <Typography>
                                        <h4>Sign In with</h4>
                                    </Typography>
                                </Box>
                            </form>
                            <Box className={styles.signinwith}>
                                <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
                                    <GoogleLogin onSuccess={handleGoogleLoginSuccess} />
                                </GoogleOAuthProvider>
                            </Box>
                            <Box className={styles.signinwith}>
                                <CustomButton {...facebookButtonProps} onClick={() => { }} />
                            </Box>
                            <Box className={styles.signinwith}>
                                <CustomButton {...linkedInButtonProps} onClick={() => { }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Signin;