import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import styles from './styles.module.scss';

interface referProps {
    title: string;
    desc: string;
    referLink: string;
    image: string;
}

const ReferCard: React.FC<referProps> = ({ title, desc, referLink, image }) => {

    const copyText = () => {
        navigator.clipboard.writeText(referLink);
    };

    return (
        <Box className={styles.refer}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={7}>
                    <Typography className={styles.typo}><h1>{title}</h1></Typography>
                    <Typography className={styles.typo}><p>{desc}</p></Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            value={referLink}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0',
                                },
                            }}
                            className={styles.textBox}
                        />
                        <Button className={styles.btn} variant="contained" onClick={copyText}>
                            Copy
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={5} className={styles.imgBox}>
                    <img src={image} alt="image" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ReferCard;