import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react';
import styles from './styles.module.scss';
interface eventsBannerProps {
    title?: string;
    rightTitle?: string;
    colorTitle: string;
    description?: string;
    buttonTitle?: string;
    redirectedLink?: string;
    image: string
}

const CollaborationBanner: React.FC<eventsBannerProps> = ({ title, rightTitle, colorTitle, description, buttonTitle, redirectedLink, image }) => {
    return (
        <Box className={styles.collaboration}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} className={styles.imgBox} sx={{ display: { xs: 'block', md: 'none' } }}>
                    <img src={image} alt="banner" />
                </Grid>
                <Grid item xs={12} md={6} margin={'auto'}>
                    <Typography className={styles.typo}><h1>{title && (title)}<span>{colorTitle}</span>{rightTitle && (rightTitle)}</h1></Typography>
                    {description && (<Typography className={styles.typo}><p>{description}</p></Typography>)}
                    {buttonTitle && (<Box>
                        <a href={redirectedLink}><Button className={styles.customBtn}>{buttonTitle}</Button></a>
                    </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={6} className={styles.imgBox} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <img src={image} alt="banner" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default CollaborationBanner;