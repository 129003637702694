import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from './styles.module.scss';
import CollabCard from '../../molecules/collabCard/collabCard';

interface CollabProps {
    image: string;
    title: string;
    linkTitle: string;
    rediredtedLink: string;
}

interface collaborationProps {
    title: string;
    description: string;
    collabCard: CollabProps[];
}

const Collaboration: React.FC<collaborationProps> = ({ title, description, collabCard }) => {
    return (
        <>
            <Box className={styles.collaboration}>
                <Typography className={styles.typo}>
                    <h1>{title}</h1>
                </Typography>
                <Typography className={styles.typo}>
                    <p>{description}</p>
                </Typography>
            </Box>
            <Grid container spacing={3}>
                {collabCard.map((item, index) => (
                    <Grid item xs={6} sm={6} md={3} key={index}>
                        <CollabCard collabCard={item} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Collaboration;
