import React from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import styles from "./styles.module.scss";
import { RefreshOutlined } from '@mui/icons-material';
import apiService from '../../../service/apiService';
import { OTP_VERIFICATION, RESEND_OTP } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

interface OTPInputProps {
    length?: number;
    separator?: string;
    value: string;
    onChange: (otp: string) => void;
    email: string;
    source?: string;
}

const Otp: React.FC<OTPInputProps> = ({ length = 4, value, onChange, email, source }) => {
    const otpArray = value.split('');
    const navigate = useNavigate();

    const handleChange = (index: number, val: string) => {
        const newOtp = [...otpArray];
        newOtp[index] = val.replace(/\D/g, '');
        if (val && index < length - 1) {
            document.getElementById(`otp-input-${index + 1}`)?.focus();
        }
        if (!val && index > 0) {
            document.getElementById(`otp-input-${index - 1}`)?.focus();
        }

        onChange(newOtp.join(''));
    };

    const resendOtp = async () => {
        try {
            const response = await apiService.postApi(`${RESEND_OTP}${email}`, {
                email,
                otp: value,
            });
            if (response && response.data) {

                alert('OTP resent successfully.');
            }
            else {
                alert('Resend OTP failed');
            }
        } catch (error) {
            alert('Failed to resend OTP. Please try again.');
        }
    };

    const verifyOtp = async () => {
        try {
            const response = await apiService.postApi(`${OTP_VERIFICATION}${email}`, {
                otp: value,
            });
            if (response && response.data) {
                alert('OTP verified successfully.');

                if (source === 'signup') {
                    navigate('/signin');
                } else if (source === 'forgot-password') {
                    navigate('/reset-password', { state: { email, otp: value } });
                } else {
                    navigate('/signin');
                }
            } else {
                alert('OTP verification failed. Please try again.');
            }
        } catch (error) {
            alert('Verification failed. Please try again.');
        }
    };

    return (
        <Box display={"block"}>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                {Array.from({ length }).map((_, index) => (
                    <React.Fragment key={index}>
                        <TextField
                            id={`otp-input-${index}`}
                            value={otpArray[index] || ''}
                            onChange={(e) => handleChange(index, e.target.value)}
                            variant="outlined"
                            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                            sx={{ width: '60px', background: '#F4F4F4' }}
                        />
                        {index < length - 1}
                    </React.Fragment>
                ))}
            </Box>
            <Box>
                <Typography className={styles.typo} onClick={resendOtp}>
                    <RefreshOutlined /><p>Resend Code</p>
                </Typography>
            </Box>
            <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2, p: 1.5, textTransform: 'capitalize' }}
                onClick={verifyOtp}
            >
                Verify OTP
            </Button>
        </Box>
    );
};

export default Otp;
