import { Box, Typography } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss';

interface headerInfoProps {
    selectedItem: string;
    address: string;
    icon?: string;
    onIconClick?: () => void;
}

const PageLocationText: React.FC<headerInfoProps> = ({ selectedItem, address, icon, onIconClick }) => {
    return (
        <Box className={styles.box}>
            <Typography variant="h3" component="div">
                <h3>{selectedItem}</h3>
            </Typography>
            <Typography variant="h6" component="div">
                <h6><span>{address}</span>
                    {icon && (
                        <img
                            src={icon}
                            alt="edit-icon"
                            style={{ cursor: onIconClick ? 'pointer' : 'default', marginLeft: '8px' }}
                            onClick={onIconClick}
                        />
                    )}</h6>
            </Typography>
        </Box>
    )
}

export default PageLocationText;