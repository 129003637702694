import React from 'react';
import styles from './styles.module.scss'
import { Box, Grid, Typography } from '@mui/material';

interface empowerProps {
  image: string;
  title: string;
  description: string;
}

const EmpowerCard: React.FC<{ empowerCard: empowerProps }> = ({ empowerCard }) => {
  return (
    <Box>
      <Grid container className={styles.empower}>
        <Grid item xs={3} sm={4} lg={3} className={styles.imgBox}>
          <img src={empowerCard.image} alt="card-icon" />
        </Grid>
        <Grid item xs={9} sm={8} lg={9} className={styles.contentBox}>
          <Typography className={styles.typo}><h2>{empowerCard.title}</h2></Typography>
          <Typography className={styles.typo}><p>{empowerCard.description}</p></Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmpowerCard;