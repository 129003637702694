import React from 'react';
import styles from './styles.module.scss'
import { Box, Button, Grid, Typography } from '@mui/material';
import PrimaryButton from '../../atoms/primaryBotton/primaryButton';

interface messageData1Props {
    image: string;
    title: string;
    description: string;
    noteTitle: string;
    noteTitle2?: string;
    buttonTitle: string;
    buttonRedirectLnk: string;
}

const MessageCard1: React.FC<messageData1Props> = ({ image, title, description, noteTitle, noteTitle2, buttonTitle, buttonRedirectLnk }) => {
    return (
        <Box className={styles.messageCard}>
            <Grid container spacing={6}>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <img src={image} alt="image-icon" />
                </Grid>
                <Grid item xs={12} lg={6} display={'flex'}>
                    <Box className={styles.leftBorder}></Box>
                    <Box className={styles.contents}>
                        <Typography className={styles.typo}><h1>{title}</h1></Typography>
                        <Typography className={styles.typo}><p>{description}</p></Typography>
                        <Box className={styles.btn} sx={{ display: { xs: 'none', lg: 'block' } }}>
                            <PrimaryButton buttonTitle={buttonTitle} redirectLink={buttonRedirectLnk} />
                        </Box>
                        <Box className={styles.note}>
                            <Typography className={styles.typo}>
                                <h2>{noteTitle}</h2>
                            </Typography>
                            {noteTitle2 && (<Typography className={styles.typo} mt={1}>
                                <h2>{noteTitle2}</h2>
                            </Typography>)}
                        </Box>
                        <Box className={styles.btn} sx={{ display: { xs: 'block', lg: 'none' } }}>
                            <PrimaryButton buttonTitle={buttonTitle} redirectLink={buttonRedirectLnk} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <img src={image} alt="image-icon" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default MessageCard1;