import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Box, Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { CHANGE_PASSWORD } from '../../utils/constants';
import apiService from '../../service/apiService';

const ResetPassword: React.FC = () => {
    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: '',
    });

    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { email, otp } = location.state || {};

    useEffect(() => {
        if (!email || !otp) {
            navigate('/forgot-password');
        }
    }, [email, otp, navigate]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        if (formData.new_password !== formData.confirm_password) {
            setError('Passwords do not match. Please try again.');
            return;
        }
        try {
            const response = await apiService.putApi(`${CHANGE_PASSWORD}${email}`, {
                new_password: formData.new_password,
                confirm_password: formData.confirm_password,
                otp,
            });
            if (response && response.data) {
                const user = response.data.user;
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/successful');
            } else {
                setError('Reset password failed. Please try again.');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            setError('An error occurred during password reset. Please try again.');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Box className={styles.signUp}>
            <Container maxWidth='xl' className={styles.containerPadding}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={6} className={styles.imageBox}>
                        <img src="/assets/images/forgot-banner.png" alt="login-banner" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: { xs: '1rem', md: '2rem', lg: '2rem 3rem 0rem 2rem' }, display: 'grid' }}>
                        <Box className={styles.formBox} sx={{ padding: { xs: '1rem', md: '2rem', lg: '3rem' } }}>
                            <Typography variant="h1" gutterBottom className={styles.typo}>
                                <h1>Reset Your Password</h1>
                            </Typography>
                            <Typography gutterBottom className={styles.typo}>
                                <p>Ready for a fresh password? Let’s get you set up!</p>
                            </Typography>
                            {error && <Typography color="error">{error}</Typography>}
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="new-password" className={styles.label}>Add New Password</InputLabel>
                                    <TextField
                                        id="new-password"
                                        name="new_password"
                                        variant="outlined"
                                        type='password'
                                        fullWidth
                                        value={formData.new_password}
                                        onChange={handleChange}
                                        required
                                        className={styles.inputField}
                                    />
                                </Box>

                                <Box sx={{ mb: '1rem' }}>
                                    <InputLabel htmlFor="confirm-password" className={styles.label}>Confirm Password</InputLabel>
                                    <TextField
                                        id="confirm-password"
                                        name="confirm_password"
                                        variant="outlined"
                                        type='password'
                                        fullWidth
                                        value={formData.confirm_password}
                                        onChange={handleChange}
                                        required
                                        className={styles.inputField}
                                    />
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2, p: 1.5, textTransform: 'capitalize' }}
                                >
                                    Reset Password
                                </Button>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ResetPassword;