import React from 'react';
import styles from './styles.module.scss';
import { Box, Grid, Typography } from '@mui/material';
import MissionCard from '../../molecules/missionCard/missionCard';

interface missionCardProps {
  id: string;
  image: string;
  imageBg?: string;
  title: string;
  description?: string;
}

interface chooseProps {
  title: string;
  description?: string;
  chooseCardProps: missionCardProps[];
}

const ChooseUs: React.FC<chooseProps> = ({ title, description, chooseCardProps }) => {
  return (
    <Box>
      <Box>
        <Typography className={styles.mission}>
          <h1>{title}</h1>
        </Typography>
        <Typography className={`${styles.mission} ${styles.typoPMargin}`}>
          <p>{description}</p>
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {chooseCardProps.map((data) => (
          <Grid
            item
            xs={6}
            md={4}
            gap={8}
            key={data.id}
            sx={{padding:{lg:'0rem 4rem'}}}
            className={styles.cardBox}
          >
            <MissionCard missionCardData={data} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default ChooseUs;